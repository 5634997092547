import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusCircleOutlined, FileOutlined } from '@ant-design/icons';

import { StoresTable } from '@Features/seller/stores';
import { AddStoreModal } from '@Features/modals';
import { selectState, StoresKeyValueType } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Button, Typography, Empty, Row, Grid } from '@Shared/ui';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const StoresTab = () => {
    const breakpoints = useBreakpoint();
    const [openAddStoreModal, setOpenAddStoreModal] = useState<boolean>(false);
    const { isLoading, value }: StoresKeyValueType = useSelector(selectState);

    const renderAddStoreButton = (ghost: boolean) => (
        <Button
            icon={<PlusCircleOutlined />}
            iconPosition="start"
            type="primary"
            ghost={ghost}
            onClick={() => setOpenAddStoreModal(true)}
        >
            Добавить магазин
        </Button>
    );

    const EmptyTableView = (
        <Empty
            image={<FileOutlined />}
            imageStyle={{ height: 22 }}
            description={null}
        >
            <Title level={2}>Здесь пока пусто</Title>
            <Row
                justify="center"
                style={{ marginBottom: 24 }}
            >
                <Text type="secondary">В этом разделе могут храниться ваши магазины.</Text>
            </Row>
            {renderAddStoreButton(false)}
        </Empty>
    );

    return (
        <>
            <Row
                justify="space-between"
                align="top"
                style={{ columnGap: 16, rowGap: 24, flexDirection: breakpoints.lg ? 'row' : 'column-reverse' }}
                wrap={false}
            >
                <Text type="secondary">
                    В этом разделе хранится список ваших магазинов и маркетплейсов подключенных к ним.{' '}
                </Text>
                {renderAddStoreButton(true)}
            </Row>
            <StoresTable
                data={value?.items || []}
                isLoading={isLoading}
                EmptyTableView={EmptyTableView}
            />
            <AddStoreModal
                open={openAddStoreModal}
                setOpen={setOpenAddStoreModal}
            />
        </>
    );
};
export default StoresTab;
