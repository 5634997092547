import { useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';

import {
    selectState,
    EditStoreFormType,
    submitForm,
    resetForm,
} from '@Entities/forms/seller/stores/model/editStoreFormSlice';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { Button, Flex, Form, Typography, Input, Upload, message } from '@Shared/ui';
import { FormKeysEnum, KeyValueKeysEnum } from '@Shared/model/seller/types';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';
import { uploadImageRequest } from '@Shared/utils/uploadImage';

import type { UploadProps } from 'antd';

const { TextArea } = Input;
const { Title } = Typography;

type StoreDetailsFormProps = {
    setOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    storeId: string;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
    setDrawerExtraContent: Dispatch<SetStateAction<ReactNode>>;
    setDrawerTitle: Dispatch<SetStateAction<ReactNode>>;
};

const EditStoreForm = ({
    setOpen,
    storeId,
    setModalType,
    setModalOpen,
    setDrawerExtraContent,
    setDrawerTitle,
    setDrawerContentType,
}: StoreDetailsFormProps) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { initialData, isSubmitting, formData, isSubmitted, errors }: EditStoreFormType = useSelector(selectState);

    const handleSubmit = (formValues: typeof formData) => {
        const modifiedData = { ...formValues, comment: 'comment' };
        dispatch(
            submitForm({
                key: FormKeysEnum.sellerEditStoreForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Магазин успешно обновлен',
                    errorMessage: 'Ошибка при попытке обновлении магазина',
                },
                formData: modifiedData,
                params: { storeId },
            }),
        );
    };

    useEffect(() => {
        setDrawerTitle(
            <Button
                type="text"
                onClick={() => setDrawerContentType('storeDetails')}
            >
                Назад
            </Button>,
        );
    }, [setDrawerContentType, setDrawerTitle]);

    useEffect(() => {
        setDrawerExtraContent(
            <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isSubmitting}
            >
                Сохранить
            </Button>,
        );
    }, [form, isSubmitting, setDrawerExtraContent, storeId]);

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        const isDataEmpty = !Object.keys(initialData).length;
        if (isDataEmpty) {
            form.resetFields();
        } else {
            form.setFieldsValue(initialData);
        }
    }, [form, initialData]);

    useEffect(() => {
        if (isSubmitted) {
            setOpen(false);
            dispatch(resetForm());
            dispatch(fetchData({ key: KeyValueKeysEnum.getStoresList }));
        }
    }, [dispatch, isSubmitted, setOpen]);

    const uploadProps: UploadProps = {
        name: 'file',
        accept: 'image/png, image/svg+xml, image/jpeg',
        customRequest: uploadImageRequest,
        listType: 'picture',
        defaultFileList: [
            {
                uid: formData.logo,
                status: 'done',
                url: formData.logo,
            },
        ],
        maxCount: 1,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                form.setFieldValue('logo', info.file.response);
            } else if (status === 'removed') {
                form.setFieldValue('logo', null);
            } else if (status === 'error') {
                message.error(`Ошибка загрузки файла ${info.file.name}.`);
            }
        },
    };

    return (
        <>
            <Flex vertical>
                <Form
                    form={form}
                    name="editStore"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Title
                        style={{ marginBottom: 8 }}
                        level={4}
                    >
                        Редактирование магазина
                    </Title>
                    <Form.Item
                        hasFeedback
                        label="Название магазина"
                        name="title"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                        style={{ marginTop: 20 }}
                        validateStatus={errors?.title ? 'error' : ''}
                        help={errors?.title || null}
                    >
                        <Input placeholder="Укажите название" />
                    </Form.Item>
                    <Form.Item
                        hasFeedback
                        label="Описание"
                        name="description"
                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                        validateStatus={errors?.description ? 'error' : ''}
                        help={errors?.description || null}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="Логотип"
                        name="logo"
                    >
                        <Upload {...uploadProps}>
                            <Button
                                type="primary"
                                icon={<UploadOutlined />}
                            >
                                Upload
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Flex>
            <Flex
                vertical
                className="edit-store__delete-button"
            >
                <Button
                    danger
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('deleteStore');
                    }}
                >
                    Удалить магазин
                </Button>
            </Flex>
        </>
    );
};

export default EditStoreForm;
