import { FC, ReactNode, PropsWithChildren } from 'react';
import { Typography, Empty } from 'antd';
import { FileOutlined } from '@ant-design/icons';

const { Title } = Typography;

const fileOutlined = () => (
    <FileOutlined
        height={28}
        width={28}
    />
);

type EmptyTableProps = PropsWithChildren & {
    icon?: typeof fileOutlined;
    title?: string | false;
    description?: string | false;
};

export const EmptyTable: FC<EmptyTableProps> = ({
    icon = fileOutlined,
    title = 'Здесь пока пусто',
    description = false,
    children,
}: PropsWithChildren<EmptyTableProps>) => {
    return (
        <Empty
            style={{ height: '240px', padding: '50px' }}
            image={icon}
            imageStyle={{ height: '28px', fontSize: 28 }}
            description={description}
        >
            {title ? <Title level={2}>{title}</Title> : null}
            {children}
        </Empty>
    );
};

export const renderEmpty = ({
    icon: Icon = FileOutlined,
    title,
    description,
    content,
}: Partial<Omit<EmptyTableProps, 'icon'>> & { icon?: typeof FileOutlined; content?: ReactNode | string }) => (
    <EmptyTable
        title={title}
        description={description}
        icon={
            Icon ? (
                <Icon
                    width={28}
                    height={28}
                />
            ) : undefined
        }
    >
        {content}
    </EmptyTable>
);
