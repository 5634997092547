import Icon from '@ant-design/icons';

import { LogoEllipse } from '@Shared/assets/images';
import {
    InternetWebLinkIcon,
    DashboardPublisherIcon,
    MediaLibraryPlaylistIcon,
    BookLegalLow,
    SingleUser,
    ExitLogOut,
} from '@Shared/assets/images/icons';

const SITE_NAME = 'Takprodam';

export const logoMenuItem = {
    className: 'sidebar-logo',
    icon: <Icon component={() => <LogoEllipse style={{ width: '22px', height: '22px' }} />} />,
    label: SITE_NAME,
};

export const pageMenuItems = [
    {
        key: 'dashboard',
        href: '/',
        icon: <Icon component={() => <DashboardPublisherIcon />} />,
        label: 'Дашбоард',
    },
    {
        key: 'sources',
        href: '/sources',
        icon: <Icon component={() => <InternetWebLinkIcon />} />,
        label: 'Площадки',
    },
    {
        key: 'products',
        href: '/products',
        icon: <Icon component={() => <MediaLibraryPlaylistIcon />} />,
        label: 'Каталог продуктов',
    },
];

export const userMenuItems = [
    {
        key: 'profile',
        label: 'Профиль',
        disabled: true,
        icon: <Icon component={() => <SingleUser />} />,
    },
    {
        key: 'legal-info',
        href: '/legal-info',
        label: 'Юридические данные',
        icon: <Icon component={() => <BookLegalLow style={{ opacity: '0.45' }} />} />,
    },
    {
        type: 'divider',
    },
    {
        key: 'logout',
        label: 'Выйти',
        disabled: true,
        icon: <Icon component={() => <ExitLogOut />} />,
    },
];
