import { FC } from 'react';

import { MPAvatar } from '@Features/seller/stores/MPAvatar';
import { MarketplaceStoreStatusEnum2 } from '@OpenApi/typescript-axios';
import { Badge } from '@Shared/ui';

import './styles.scss';

type MPAvatarWithBadgeProps = {
    slug: string;
    status: MarketplaceStoreStatusEnum2;
};

export const MPAvatarWithBadge: FC<MPAvatarWithBadgeProps> = ({ slug, status }) => {
    return (
        <Badge
            offset={[0, 6]}
            dot={status === MarketplaceStoreStatusEnum2.Disconnected}
        >
            <MPAvatar
                isDisconnected={status === MarketplaceStoreStatusEnum2.Disconnected}
                slug={slug}
            />
        </Badge>
    );
};
