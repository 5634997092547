import OzonIcon from './marketplaces/ozon.svg';
import WildberriesIcon from './marketplaces/wildberries.svg';
import YandexMarketIcon from './marketplaces/yandex-market.svg';
import InteractionIcon from './interaction.svg';
import InternetWebLinkIcon from './browsers-internet-web-link-right-bottom.svg';
import BrowserCursorClick from './browser-cursor-click.svg';
import NotesPagesNotification from './notes-pages-notification.svg';
import FileBlankCopyExchange from './file-blank-copy-exchange.svg';
import DashboardPublisherIcon from './dashboard-publisher.svg';
import MediaLibraryPlaylistIcon from './media-library-playlist-favorite.svg';
import CopyItemLeftTop from './copy-item-left-top.svg';
import MailboxDownload from './mailbox-download.svg';
import BookLegalLow from './book-legal-low.svg';
import ExitLogOut from './exit-log-out.svg';
import SingleUser from './single-user.svg';

const MarketplaceIcons = {
    OzonIcon,
    WildberriesIcon,
    YandexMarketIcon,
};

export {
    MarketplaceIcons,
    InteractionIcon,
    InternetWebLinkIcon,
    DashboardPublisherIcon,
    BrowserCursorClick,
    NotesPagesNotification,
    FileBlankCopyExchange,
    MediaLibraryPlaylistIcon,
    CopyItemLeftTop,
    MailboxDownload,
    BookLegalLow,
    ExitLogOut,
    SingleUser,
};
