import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button, Form, Input, Typography, Space, Card, Select, Skeleton, Grid, Row } from '@Shared/ui';
import { ConfirmLeavePrompt } from '@Features/shared';
import {
    useViewSellerLegalInfoQuery,
    useAddSellerLegalInfoMutation,
    LegalInfoDto,
    LegalTypeEnum,
} from '@Shared/api/rtkQueryApis/sellerLegalInfo';
import { useGetCountryListQuery } from '@Shared/api/rtkQueryApis/common/commonApi';
import { useYup } from '@Shared/api/hooks';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import './styles.scss';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const legalTypeMap = {
    natural_person: 'Физическое лицо',
    individual_entrepreneur: 'Индивидуальный предприниматель',
    company: 'Юридическое лицо',
};

const LegalInfoForm = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const breakpoints = useBreakpoint();
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('RU');
    const [selectedLegalType, setSelectedLegalType] = useState(LegalTypeEnum.Company);
    const { data: legalInfo, isFetching: isLegalInfoLoading } = useViewSellerLegalInfoQuery();
    const { data: countriesList, isFetching: isCountriesLoading } = useGetCountryListQuery();
    const [addLegalInfo, { isLoading: isSubmitting, error: addLegalInfoError, isSuccess: isSubmitted }] =
        useAddSellerLegalInfoMutation();
    const isSelectedCountryRussia = selectedCountry === 'RU';

    const initialData = {
        ...legalInfo,
        country: legalInfo?.country?.id || 'RU',
    };

    const yupRules = useYup({
        country: yup.string().required('Это поле обязательно к заполнению'),
        legal_type: yup.string().required('Это поле обязательно к заполнению'),
        title: yup.string().required('Это поле обязательно к заполнению'),
        inn: yup.string().required('Это поле обязательно к заполнению'),
        tax_number: yup.string().required('Это поле обязательно к заполнению'),
        mobile_phone: yup.string().required('Это поле обязательно к заполнению'),
    });

    const onFinish = (formValues: LegalInfoDto) => {
        addLegalInfo({
            legalInfoDto: formValues,
            notificationOptions: {
                successMessage: 'Юридические данные успешно обновлены',
            },
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        if (!legalInfo?.legal_type) {
            form.setFieldValue('legal_type', LegalTypeEnum.Company);
        }
        if (legalInfo?.country) {
            setSelectedCountry(legalInfo.country?.id);
        }
    }, [form, legalInfo]);

    useEffect(() => {
        const errors = normalizeErrorsObject(addLegalInfoError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [addLegalInfoError, form]);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitted) {
            setUnsavedChanges(false);
        }
    }, [isSubmitted]);

    const legalTypeOptions = Object.values(LegalTypeEnum || {}).map((type) => ({
        value: type,
        label: legalTypeMap[type],
    }));

    const countriesOptions = (countriesList?.items || []).map((country) => ({
        value: country.id,
        label: country.title,
    }));

    const innLength = selectedLegalType === LegalTypeEnum.Company ? 10 : 12;

    if (isLegalInfoLoading) return <Skeleton active />;

    return (
        <Form
            form={form}
            name="legal-info"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={(changedFields) => {
                const hasTouchedField = changedFields.some((field) => field.touched);
                if (hasTouchedField) {
                    setUnsavedChanges(true);
                }
            }}
            autoComplete="off"
            className="legal-info__form"
            initialValues={initialData}
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card style={{ padding: '8px 0' }}>
                    <Title
                        level={3}
                        style={{ marginTop: 0 }}
                    >
                        Юридические данные
                    </Title>
                    <Text type="secondary">
                        Эти данные нужны для заключения оферты и разметки ссылок токенами согласно требованиям об
                        обязательной маркировке рекламы.
                    </Text>
                    <Form.Item
                        label="Страна"
                        name="country"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: '' }, ...yupRules]}
                        style={{ marginTop: 20 }}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            loading={isCountriesLoading}
                            options={countriesOptions}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder="Выберите страну"
                            onChange={(value) => setSelectedCountry(value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Субьект права"
                        name="legal_type"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{ required: true, message: '' }, ...yupRules]}
                        style={{ marginTop: 20 }}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch={false}
                            options={legalTypeOptions}
                            placeholder="Выберите субьект права"
                            onChange={(value) => {
                                form.setFieldValue('title', null);
                                setSelectedLegalType(value);
                            }}
                        />
                    </Form.Item>
                    {(selectedLegalType === LegalTypeEnum.NaturalPerson ||
                        selectedLegalType === LegalTypeEnum.IndividualEntrepreneur) && (
                        <Form.Item
                            label="ФИО"
                            name="title"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: '' }, ...yupRules]}
                            style={{ marginTop: 20, marginBottom: 0 }}
                        >
                            <Input placeholder="Глинка Михаил Иванович" />
                        </Form.Item>
                    )}
                    {selectedLegalType === LegalTypeEnum.Company && (
                        <Form.Item
                            label="Полное название компании"
                            name="title"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: '' }, ...yupRules]}
                            style={{ marginTop: 20 }}
                        >
                            <Input placeholder='ООО "Вектор"' />
                        </Form.Item>
                    )}
                    {isSelectedCountryRussia ? (
                        <Form.Item
                            label="ИНН"
                            name="inn"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: '' }, ...yupRules]}
                            style={{ marginTop: 20 }}
                        >
                            <Input placeholder={`${innLength} цифр`} />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label="Налоговый номер"
                            name="tax_number"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: '' }, ...yupRules]}
                            style={{ marginTop: 20 }}
                        >
                            <Input placeholder="Ваш уникальный номер налогоплательщика" />
                        </Form.Item>
                    )}
                    {selectedLegalType === LegalTypeEnum.NaturalPerson && !isSelectedCountryRussia && (
                        <Form.Item
                            label="Номер мобильного телефона"
                            name="mobile_phone"
                            validateTrigger="onBlur"
                            rules={[{ required: true, message: '' }, ...yupRules]}
                            style={{ marginTop: 20 }}
                        >
                            <Input placeholder="+XXXXXXXXXXX" />
                        </Form.Item>
                    )}
                    <Text type="secondary">
                        Нажимая “Подтвердить”, вы подтверждаете правильность указанных данных и принимаете{' '}
                        <a>оферту.</a>
                    </Text>
                </Card>
                <Form.Item style={{ padding: breakpoints.md ? 0 : '0 24px' }}>
                    <Row
                        className="action-buttons-block"
                        style={{
                            justifyContent: 'space-between',
                            flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                        }}
                    >
                        <Button
                            onClick={() => handleBack()}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                        >
                            Назад
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                            loading={isSubmitting}
                        >
                            Подтвердить
                        </Button>
                    </Row>
                </Form.Item>
            </Space>
        </Form>
    );
};
export default LegalInfoForm;
