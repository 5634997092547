import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
    usePatchAppPrivateSellerCampaignUpdateMutation,
    type FeeTypeEnum,
    type CampaignDto2,
    type CampaignDto,
} from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { InputNumber, Select, Form, Input, Spin, Row, Col, Space, Typography, Grid } from '@Shared/ui';

import './styles.scss';

const { useBreakpoint } = Grid;

export type CampaignMainDetailsFormProps = {
    initialData: Partial<CampaignDto2>;
};

const { Text } = Typography;

const holdTimeTooltip =
    'Время холда — это количество дней, в течение которого вы \
должны подтвердить или отклонить продажу. Если вы это не сделаете, \
продажа автоматически будет подтверждена.';

const feeTypeOptions: Array<{ value: FeeTypeEnum; label: string }> = [
    { value: 'fixed', label: 'Фиксированная' },
    { value: 'percentage', label: 'По процентам' },
];

const holdTimeOptions: Array<{ value: number; label: string }> = [
    { value: 1, label: '1 день' },
    { value: 7, label: '1 неделя' },
    { value: 14, label: '2 недели' },
    { value: 30, label: '30 дней' },
];

type FormProps = {
    title: string;
    hold_time: number;
    fee_value: string;
};

export const CampaignMainDetailsForm = ({ initialData }: CampaignMainDetailsFormProps) => {
    const breakpoints = useBreakpoint();
    const [patchAppPrivateSellerCampaignUpdate, { isLoading }] = usePatchAppPrivateSellerCampaignUpdateMutation();
    const [form] = Form.useForm();
    const [addonAfter, setAddonAfter] = useState('%');

    const handleFeeTypeChange = (value: string) => {
        setAddonAfter(value === 'fixed' ? '₽' : '%');
    };

    useEffect(() => {
        setAddonAfter(initialData.fee_setting?.fee_type === 'fixed' ? '₽' : '%');
    }, [initialData.title, initialData.fee_setting?.fee_type]);

    const handleSubmit = (data: FormProps) => {
        const campaignDto: CampaignDto = {
            title: data.title,
            fee_setting: { fee_value: (data.fee_value / 100).toString() },
            hold_time: data.hold_time,
        };

        patchAppPrivateSellerCampaignUpdate({
            slug: initialData.slug!,
            campaignDto,
        });
    };

    const handleFieldsChange = () => {
        form.submit();
    };

    const handleFieldsChangeDebounced = useDebouncedCallback(() => {
        handleFieldsChange();
    }, 1000);

    const minTotalFeeLabel = (
        <Col xs={24}>
            <Text
                type="secondary"
                style={{ fontSize: '12px' }}
            >
                Размер комиссии не может быть меньше {initialData.fee_setting?.min_total_fee} рублей
            </Text>
        </Col>
    );

    return (
        <Form
            className="campaign-details-form"
            form={form}
            initialValues={initialData}
            onFieldsChange={handleFieldsChangeDebounced}
            onFinish={handleSubmit}
            style={{ maxWidth: breakpoints.lg ? 540 : 'unset' }}
        >
            {isLoading ? (
                <Spin
                    size="small"
                    style={{ position: 'absolute', right: 'var(--ant-padding-lg)', top: 'var(--ant-padding-lg)' }}
                />
            ) : null}
            <Row
                className="campaign-type-selection__type-card__content"
                wrap
                style={{ width: '100%', alignItems: 'stretch' }}
            >
                <Form.Item
                    layout="vertical"
                    label="Название кампании"
                    name="title"
                    required
                    style={{ width: '100%' }}
                >
                    <Input placeholder="Укажите название кампании" />
                </Form.Item>
                <Space
                    direction="vertical"
                    style={{ width: '100%' }}
                    size={[16, 0]}
                >
                    <Row
                        align="bottom"
                        gutter={12}
                        wrap={breakpoints.md ? false : true}
                        style={{ marginTop: 24 }}
                    >
                        <Col
                            md={10}
                            xs={16}
                        >
                            <Form.Item
                                layout="vertical"
                                label="Коммисия по умолчанию"
                                name="fee_type"
                                // eslint-disable-next-line max-len
                                // help={` Размер комиссии не может быть меньше ${initialData.fee_setting?.min_total_fee} рублей`}
                                tooltip={
                                    'Комиссия, которая применяется \
            автоматически ко всем товарам. Если конкретному \
            товару нужно задать свою комиссию, сделайте это вручную в списке ниже.'
                                }
                            >
                                <Select
                                    disabled
                                    options={feeTypeOptions}
                                    onChange={handleFeeTypeChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            md={6}
                            xs={8}
                        >
                            <Form.Item
                                layout="vertical"
                                label="&nbsp;"
                                name="fee_value"
                                trigger="onChange"
                            >
                                <InputNumber
                                    step="1"
                                    min={initialData?.fee_setting?.min_total_fee || 10}
                                    addonAfter={addonAfter}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        {breakpoints.md ? null : minTotalFeeLabel}
                        <Col
                            md={8}
                            xs={24}
                            style={{ marginTop: breakpoints.md ? 0 : 24 }}
                        >
                            <Form.Item
                                layout="vertical"
                                label="Время холда"
                                name="hold_time"
                                tooltip={holdTimeTooltip}
                            >
                                <Select options={holdTimeOptions} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {breakpoints.md ? <Row>{minTotalFeeLabel}</Row> : null}
                </Space>
            </Row>
        </Form>
    );
};
