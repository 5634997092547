import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import notificationsReducer from '@Entities/notifications/model/notificationSlice';
import { fullApi } from '@Shared/api/full';

import { errorMiddleware } from './middleware';
import { keyValueReducer } from './keyValue/reducers';
import { formsReducer } from './forms/reducers';

const rootReducer = combineReducers({
    keyValue: keyValueReducer,
    forms: formsReducer,
    notifications: notificationsReducer,
    [fullApi.reducerPath]: fullApi.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(fullApi.middleware, errorMiddleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
