import { useSearchParams, useNavigate } from 'react-router-dom';
import { PlusCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Alert } from 'antd';

import { EmptyCampaignsTableView, CampaignsFilter, CampaignsTable } from '@Features/seller/products';
import { CampaignsFilterEnum } from '@Features/seller/products/model/types';
import { Flex, Grid, Typography, Button, Row } from '@Shared/ui';
import { useGetCampaignsListQuery } from '@Shared/api/rtkQueryApis/sellerCampaigns/SellerCampaignsApi';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';

import './styles.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const CampaignsTab = () => {
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { data: user } = useGetTheUserInformationQuery();
    const { data: campaigns, isFetching } = useGetCampaignsListQuery({
        paymentTypes: searchParams.getAll(CampaignsFilterEnum.PAYMENT_TYPES).length
            ? searchParams.getAll(CampaignsFilterEnum.PAYMENT_TYPES)
            : undefined,
    });

    return (
        <>
            {!user?.seller?.legalInfo && (
                <Row style={{ marginBottom: 24 }}>
                    <Alert
                        className="campaigns-tab__legal-info-alert"
                        description="Для продолжения работы заполните юридические данные"
                        type="info"
                        showIcon
                        icon={<InfoCircleFilled style={{ color: 'black', opacity: '0.45' }} />}
                        banner
                        action={
                            <Button
                                type="primary"
                                onClick={() => navigate('/legal-info')}
                            >
                                Заполнить
                            </Button>
                        }
                    />
                </Row>
            )}
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
                style={{ marginBottom: 24 }}
                className="campaigns-tab"
            >
                <Text type="secondary">В этом разделе представлен общий список ваших рекламных кампаний</Text>
            </Flex>
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
                style={{ marginBottom: 24 }}
            >
                <CampaignsFilter />
                <Button
                    icon={<PlusCircleOutlined />}
                    iconPosition="start"
                    type="primary"
                    onClick={() => navigate('/product-promotion/campaign-creation')}
                >
                    Создать кампанию
                </Button>
            </Flex>

            <CampaignsTable
                data={campaigns?.items || []}
                isLoading={isFetching}
                EmptyTableView={<EmptyCampaignsTableView />}
            />
        </>
    );
};
export default CampaignsTab;
