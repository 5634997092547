import { createElement, ReactNode, useState } from 'react';
import Icon from '@ant-design/icons';

import { CampaignDto2, PaymentType, CampaignStatusEnum } from '@OpenApi/typescript-axios';
import { Table, Typography, Avatar, Flex, Badge } from '@Shared/ui';
import { getMarketPlaceIconBySlug } from '@Shared/utils/stores/getMarkeplaceIconBySlug';
import { getCampaignStatusBadgeType } from '@Shared/utils/campaigns/getCampaignStatusBadgeType';

import ActionsMenu from './ActionsMenu';
import CancelCampaignModal from './CancelCampaignModal';
import LaunchCampaignButton from './LaunchCampaignButton';

const { Text } = Typography;

type CampaignsTableProps = { data: CampaignDto2[]; isLoading: boolean; EmptyTableView: ReactNode };

export const CampaignsTable = ({ data, isLoading, EmptyTableView }: CampaignsTableProps) => {
    const [currentCampaignId, setCurrentCampaignId] = useState('');
    const [isCancelCampaignModalOpen, setCancelCampaignModalOpen] = useState(false);

    const columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            ellipsis: true,
            render: (title: string, record: CampaignDto2) => (
                <Flex>
                    <Avatar
                        size={36}
                        shape="square"
                        style={{ minWidth: 36 }}
                        icon={
                            <Icon
                                component={() =>
                                    createElement(
                                        getMarketPlaceIconBySlug(record?.marketplace_store?.marketplace?.slug),
                                    )
                                }
                            />
                        }
                    />
                    <span style={{ marginLeft: 12, whiteSpace: 'pre-wrap' }}>
                        <Text strong>{title}</Text>
                        <br />
                        <Text type="secondary">{record?.marketplace_store?.store?.title}</Text>
                    </span>
                </Flex>
            ),
        },
        {
            title: 'Тип кампании',
            dataIndex: 'payment_type',
            key: 'payment_type',
            render: (payment_type: PaymentType) => <>{payment_type?.title}</>,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (status: CampaignStatusEnum) => (
                <Badge
                    status={getCampaignStatusBadgeType(status)}
                    text={status}
                />
            ),
        },
        {
            title: 'Площадки',
            dataIndex: 'source_campaign_fees',
            key: 'source_campaign_fees',
            ellipsis: true,
            render: (source_campaign_fees: number[]) => <>{source_campaign_fees?.length}</>,
        },
        {
            align: 'right',
            title: '',
            key: 'actions',
            ellipsis: true,
            render: (_, record: CampaignDto2) => (
                <>
                    {record.status === 'pending' && <LaunchCampaignButton campaignId={record?.slug} />}
                    <ActionsMenu
                        setCancelCampaignModalOpen={setCancelCampaignModalOpen}
                        campaignStatus={record.status}
                        slug={record?.slug}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                dataSource={data}
                // dataSource={campaigns}
                loading={isLoading}
                columns={columns}
                scroll={{ x: '1000px' }}
                pagination={false}
                className="campaigns-table"
                locale={{
                    emptyText: EmptyTableView,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setCurrentCampaignId(record.id);
                        },
                    };
                }}
            />
            <CancelCampaignModal
                campaignId={currentCampaignId}
                open={isCancelCampaignModalOpen}
                setOpen={setCancelCampaignModalOpen}
            />
        </>
    );
};
