import { FC } from 'react';
import { Provider } from 'react-redux';

import TopLevelWrapper from './TopLevelWrapper';

import Theme from '../providers/ThemeProvider';
import { store } from '../providers/redux/store';

const App: FC = () => {
    return (
        <>
            <Provider store={store}>
                <Theme>
                    <TopLevelWrapper />
                </Theme>
            </Provider>
        </>
    );
};

export default App;
