import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';

import { SourcesTable, DeleteSourceModal } from '@Features/publisher/sources';
import { Card, Flex, Grid, Space, Typography, Button } from '@Shared/ui';
import { useGetSourcesListQuery } from '@Shared/api/rtkQueryApis/publisherSources';
import { SourceDrawer } from '@Features/publisher/sources/SourceDetailsDrawer';
import { Breadcrumbs, type BreadcrumbItemType } from '@Features/shared';

const { Content } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const pageBreadcrumbs: BreadcrumbItemType[] = [
    {
        path: '/sources',
        title: 'Площадки',
    },
];

const SourcesPage = () => {
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const location = useLocation();
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isDeletionModalOpen, setDeletionModalOpen] = useState(false);
    const [currentSourceId, setCurrentSourceId] = useState('');
    const { isFetching, data } = useGetSourcesListQuery();

    useEffect(() => {
        if (location?.state?.initialSourceId) {
            setCurrentSourceId(location.state.initialSourceId);
            setDrawerOpen(true);
            window.history.replaceState({}, '');
        }
    }, [location]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content sources-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <Breadcrumbs items={pageBreadcrumbs} />

            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card style={{ width: '100%' }}>
                    <Flex
                        justify="space-between"
                        align={breakpoints.lg ? 'center' : breakpoints.sm ? 'flex-end' : 'unset'}
                        gap={10}
                        vertical={!breakpoints.lg}
                        style={{ marginBottom: 24 }}
                    >
                        <Flex
                            vertical
                            justify="space-between"
                        >
                            <Title
                                level={3}
                                style={{ marginTop: 0 }}
                            >
                                Список площадок
                            </Title>
                            <Text type="secondary">
                                В этом разделе вы можете добавлять свои площадки, чтобы продвигать на них товары
                                продавцов и получать прибыль.
                            </Text>
                            <Text
                                type="secondary"
                                style={{ display: 'inline-block', marginTop: 10 }}
                            >
                                Площадка — это любая онлайн-платформа (веб-сайт, канал в соц. сетях или мессенджерах,
                                приложение, расширение), которую вы хотите монетизировать.
                            </Text>
                        </Flex>
                        <Button
                            icon={<PlusCircleOutlined />}
                            iconPosition="start"
                            type="primary"
                            ghost
                            onClick={() => navigate('/sources/add-source')}
                        >
                            Добавить площадку
                        </Button>
                    </Flex>
                    <SourcesTable
                        data={data?.items || []}
                        isLoading={isFetching}
                        setDrawerOpen={setDrawerOpen}
                        setDeletionModalOpen={setDeletionModalOpen}
                        setCurrentSourceId={setCurrentSourceId}
                    />
                    <SourceDrawer
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        sourceId={currentSourceId}
                        setModalOpen={setDeletionModalOpen}
                    />
                    <DeleteSourceModal
                        sourceId={currentSourceId}
                        open={isDeletionModalOpen}
                        setOpen={setDeletionModalOpen}
                        setDrawerOpen={setDrawerOpen}
                    />
                </Card>
            </Space>
        </Content>
    );
};
export default SourcesPage;
