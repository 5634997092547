import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import {
    selectState,
    SetupIntegrationFormType,
    submitForm,
} from '@Entities/forms/seller/stores/model/setupIntegration';
import { FormIntegrationsEnum } from '@Shared/model/seller/types';
import { Button, Form, Input, Typography, Divider, Popover } from '@Shared/ui';
import { OzonIntegrationDto } from '@OpenApi/typescript-axios';
import { ConfirmLeavePrompt } from '@Features/shared';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';

import CreateCampaignDraftBlock from '../CreateCampaignDraftBlock';

import './styles.scss';

type SetupIntegrationFormProps = {
    storeId: string;
};

const { Title } = Typography;

const OzonIntegrationForm = ({ storeId }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [createCampaignDraft, setCreateCampaignDraft] = useState(true);
    const { isSubmitted, isSubmitting, formData, errors }: SetupIntegrationFormType<OzonIntegrationDto> =
        useSelector(selectState);

    const {
        token: { colorTextDescription },
    } = theme.useToken();

    const handleSubmit = (formValues: typeof formData) => {
        dispatch(
            submitForm({
                key: FormIntegrationsEnum.sellerOzonSetupIntegrationForm,
                withNotification: true,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно создана',
                    errorMessage: 'Ошибка при попытке создания интеграции',
                },
                formData: formValues,
                params: { storeId, createCampaignDraft },
            }),
        );
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitting) {
            setUnsavedChanges(false);
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (isSubmitted) {
            if (createCampaignDraft) {
                setTimeout(() =>
                    dispatch(
                        showNotification({
                            componentProps: {
                                type: NotificationType.Info,
                                message: 'У вас появилась новая рекламная кампания',
                                description:
                                    // eslint-disable-next-line max-len
                                    'Проверьте и отредактируйте информацию о ней. Запустите кампанию, чтобы начать быстрое продвижение товаров.',
                                btn: (
                                    <Button
                                        type="link"
                                        onClick={() => navigate('/product-promotion/campaigns')}
                                    >
                                        Рекламные кампании
                                    </Button>
                                ),
                            },
                        }),
                        1000,
                    ),
                );
            }
        }
    }, [createCampaignDraft, dispatch, isSubmitted, navigate]);

    return (
        <Form
            form={form}
            name="ozon-integration"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={() => setUnsavedChanges(true)}
            autoComplete="off"
            className="setup-integration-page__form"
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Form.Item
                hasFeedback
                label={
                    <>
                        ID организации{' '}
                        <Popover
                            content="text"
                            placement="right"
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
                        </Popover>
                    </>
                }
                name="organization_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.organization_id ? 'error' : ''}
                help={errors?.organization_id || null}
            >
                <Input placeholder="Укажите ID организации" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Perfomance API
            </Title>
            <Form.Item
                hasFeedback
                label={
                    <>
                        Client ID{' '}
                        <Popover
                            content="text"
                            placement="right"
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
                        </Popover>
                    </>
                }
                name="performance_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_client_id ? 'error' : ''}
                help={errors?.performance_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label={
                    <>
                        API key{' '}
                        <Popover
                            content="text"
                            placement="right"
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
                        </Popover>
                    </>
                }
                name="performance_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.performance_api_key ? 'error' : ''}
                help={errors?.performance_api_key || null}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <Divider />
            <Title
                style={{ marginBottom: 8 }}
                level={5}
            >
                Seller API
            </Title>
            <Form.Item
                hasFeedback
                label={
                    <>
                        Client ID{' '}
                        <Popover
                            content="text"
                            placement="right"
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
                        </Popover>
                    </>
                }
                name="seller_client_id"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_client_id ? 'error' : ''}
                help={errors?.seller_client_id || null}
            >
                <Input placeholder="Укажите Client ID" />
            </Form.Item>
            <Form.Item
                hasFeedback
                label={
                    <>
                        API key{' '}
                        <Popover
                            content="text"
                            placement="right"
                        >
                            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
                        </Popover>
                    </>
                }
                name="seller_api_key"
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
                validateStatus={errors?.seller_api_key ? 'error' : ''}
                help={errors?.seller_api_key || null}
            >
                <Input placeholder="Укажите API key" />
            </Form.Item>
            <CreateCampaignDraftBlock
                createCampaignDraft={createCampaignDraft}
                setCreateCampaignDraft={setCreateCampaignDraft}
            />
            <Form.Item
                className="action-buttons-block"
                style={{ marginTop: 20 }}
            >
                <Button onClick={() => handleBack()}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
export default OzonIntegrationForm;
