import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { publisher_app_router } from '../routes';

const PublisherApp: FC = () => {
    return (
        <RouterProvider
            router={publisher_app_router}
            fallbackElement={<div>Page not found</div>}
        />
    );
};

export default PublisherApp;
