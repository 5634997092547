import { FileOutlined } from '@ant-design/icons';

import { Avatar, Typography } from '@Shared/ui';
import { type Product } from '@Shared/api/rtkQueryApis/sellerCampaigns';

const { Text } = Typography;

export const ProductNameCell = ({ product }: { product: Product }) => {
    return (
        <>
            <Avatar
                shape="square"
                icon={<FileOutlined />}
                style={{ float: 'left', marginRight: 'var(--ant-margin-sm)' }}
            />

            <Text strong>{product.title}</Text>
            <br />
            <Text type="secondary">Арт.: {product.external_id}</Text>
        </>
    );
};
