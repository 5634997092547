/* tslint:disable */
/* eslint-disable */
/**
 * Catuik APP
 * The app to help
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddPublisherLegalInfo422Response
 */
export interface AddPublisherLegalInfo422Response {
    /**
     * 
     * @type {string}
     * @memberof AddPublisherLegalInfo422Response
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddPublisherLegalInfo422Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddPublisherLegalInfo422Response
     */
    'detail'?: string;
    /**
     * 
     * @type {Array<AddPublisherLegalInfo422ResponseViolationsInner>}
     * @memberof AddPublisherLegalInfo422Response
     */
    'violations'?: Array<AddPublisherLegalInfo422ResponseViolationsInner>;
}
/**
 * 
 * @export
 * @interface AddPublisherLegalInfo422ResponseViolationsInner
 */
export interface AddPublisherLegalInfo422ResponseViolationsInner {
    /**
     * 
     * @type {string}
     * @memberof AddPublisherLegalInfo422ResponseViolationsInner
     */
    'propertyPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddPublisherLegalInfo422ResponseViolationsInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddPublisherLegalInfo422ResponseViolationsInner
     */
    'template'?: string;
    /**
     * Key value array<string, string> of template parameters
     * @type {{ [key: string]: string; }}
     * @memberof AddPublisherLegalInfo422ResponseViolationsInner
     */
    'parameters'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'marketplace_url': string;
    /**
     * 
     * @type {CampaignDto2PaymentType}
     * @memberof Campaign
     */
    'payment_type'?: CampaignDto2PaymentType | null;
    /**
     * 
     * @type {CampaignStatusEnum}
     * @memberof Campaign
     */
    'status'?: CampaignStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    'holdTime'?: number;
    /**
     * 
     * @type {CampaignDto2FeeSetting}
     * @memberof Campaign
     */
    'fee_setting'?: CampaignDto2FeeSetting | null;
}


/**
 * 
 * @export
 * @interface CampaignDto
 */
export interface CampaignDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    'title': string | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    'hold_time': number | null;
    /**
     * 
     * @type {CampaignDtoFeeSetting}
     * @memberof CampaignDto
     */
    'fee_setting': CampaignDtoFeeSetting | null;
}
/**
 * 
 * @export
 * @interface CampaignDto2
 */
export interface CampaignDto2 {
    /**
     * 
     * @type {string}
     * @memberof CampaignDto2
     */
    'slug'?: string | null;
    /**
     * 
     * @type {CampaignDto2MarketplaceStore}
     * @memberof CampaignDto2
     */
    'marketplace_store'?: CampaignDto2MarketplaceStore | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto2
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto2
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto2
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto2
     */
    'marketplace_url'?: string | null;
    /**
     * 
     * @type {CampaignDto2PaymentType}
     * @memberof CampaignDto2
     */
    'payment_type'?: CampaignDto2PaymentType | null;
    /**
     * 
     * @type {CampaignDto2Status}
     * @memberof CampaignDto2
     */
    'status'?: CampaignDto2Status | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto2
     */
    'hold_time': number | null;
    /**
     * 
     * @type {CampaignDto2FeeSetting}
     * @memberof CampaignDto2
     */
    'fee_setting': CampaignDto2FeeSetting | null;
    /**
     * 
     * @type {Array<SourceCampaignFee>}
     * @memberof CampaignDto2
     */
    'source_campaign_fees'?: Array<SourceCampaignFee>;
}
/**
 * @type CampaignDto2FeeSetting
 * @export
 */
export type CampaignDto2FeeSetting = FeeSetting2;

/**
 * @type CampaignDto2MarketplaceStore
 * @export
 */
export type CampaignDto2MarketplaceStore = MarketplaceStore2;

/**
 * @type CampaignDto2PaymentType
 * @export
 */
export type CampaignDto2PaymentType = PaymentType;

/**
 * @type CampaignDto2Status
 * @export
 */
export type CampaignDto2Status = CampaignStatusEnum;

/**
 * 
 * @export
 * @interface CampaignDto3
 */
export interface CampaignDto3 {
    /**
     * 
     * @type {number}
     * @memberof CampaignDto3
     */
    'id'?: number | null;
    /**
     * 
     * @type {CampaignDto2MarketplaceStore}
     * @memberof CampaignDto3
     */
    'marketplace_store'?: CampaignDto2MarketplaceStore | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto3
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto3
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto3
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto3
     */
    'marketplace_url'?: string | null;
    /**
     * 
     * @type {CampaignDto2PaymentType}
     * @memberof CampaignDto3
     */
    'payment_type'?: CampaignDto2PaymentType | null;
    /**
     * 
     * @type {CampaignDto2Status}
     * @memberof CampaignDto3
     */
    'status'?: CampaignDto2Status | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignDto3
     */
    'hold_time': number | null;
    /**
     * 
     * @type {CampaignDto2FeeSetting}
     * @memberof CampaignDto3
     */
    'fee_setting': CampaignDto2FeeSetting | null;
    /**
     * 
     * @type {Array<SourceCampaignFee>}
     * @memberof CampaignDto3
     */
    'source_campaign_fees'?: Array<SourceCampaignFee>;
}
/**
 * @type CampaignDtoFeeSetting
 * @export
 */
export type CampaignDtoFeeSetting = FeeSetting;

/**
 * 
 * @export
 * @interface CampaignDtoList
 */
export interface CampaignDtoList {
    /**
     * 
     * @type {Array<CampaignDto2>}
     * @memberof CampaignDtoList
     */
    'items'?: Array<CampaignDto2>;
}
/**
 * 
 * @export
 * @interface CampaignFilterDto
 */
export interface CampaignFilterDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof CampaignFilterDto
     */
    'payment_types'?: Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CampaignStatusEnum = {
    Pending: 'pending',
    Active: 'active',
    TopUpBalance: 'top_up_balance',
    AddLegalInfo: 'add_legal_info',
    Verifying: 'verifying',
    Disabled: 'disabled'
} as const;

export type CampaignStatusEnum = typeof CampaignStatusEnum[keyof typeof CampaignStatusEnum];


/**
 * 
 * @export
 * @interface ChangeCommissionDto
 */
export interface ChangeCommissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeCommissionDto
     */
    'select_all'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChangeCommissionDto
     */
    'commission': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeCommissionDto
     */
    'products'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CountryDto
 */
export interface CountryDto {
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CountryDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CountryListDto
 */
export interface CountryListDto {
    /**
     * 
     * @type {Array<CountryDto>}
     * @memberof CountryListDto
     */
    'items'?: Array<CountryDto>;
}
/**
 * 
 * @export
 * @interface Credit
 */
export interface Credit {
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    'id': number;
    /**
     * 
     * @type {SellerUser}
     * @memberof Credit
     */
    'user'?: SellerUser | null;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'shortCode': string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    'symbol': string;
}
/**
 * 
 * @export
 * @interface FeeSetting
 */
export interface FeeSetting {
    /**
     * 
     * @type {string}
     * @memberof FeeSetting
     */
    'fee_value'?: string;
}
/**
 * 
 * @export
 * @interface FeeSetting2
 */
export interface FeeSetting2 {
    /**
     * 
     * @type {string}
     * @memberof FeeSetting2
     */
    'id': string;
    /**
     * 
     * @type {FeeTypeEnum}
     * @memberof FeeSetting2
     */
    'fee_type'?: FeeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeeSetting2
     */
    'fee_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeeSetting2
     */
    'system_rate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeeSetting2
     */
    'min_total_fee'?: string;
}


/**
 * 
 * @export
 * @interface FeeSettingDto
 */
export interface FeeSettingDto {
    /**
     * 
     * @type {string}
     * @memberof FeeSettingDto
     */
    'id': string;
    /**
     * 
     * @type {FeeSettingDtoFeeType}
     * @memberof FeeSettingDto
     */
    'fee_type'?: FeeSettingDtoFeeType | null;
    /**
     * 
     * @type {number}
     * @memberof FeeSettingDto
     */
    'fee_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeeSettingDto
     */
    'system_rate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeeSettingDto
     */
    'min_total_fee'?: number | null;
}
/**
 * @type FeeSettingDtoFeeType
 * @export
 */
export type FeeSettingDtoFeeType = FeeTypeEnum;

/**
 * 
 * @export
 * @enum {string}
 */

export const FeeTypeEnum = {
    Fixed: 'fixed',
    Percentage: 'percentage'
} as const;

export type FeeTypeEnum = typeof FeeTypeEnum[keyof typeof FeeTypeEnum];


/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface GetAllPublishers200Response
 */
export interface GetAllPublishers200Response {
    /**
     * 
     * @type {number}
     * @memberof GetAllPublishers200Response
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllPublishers200Response
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllPublishers200Response
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<PublisherDto>}
     * @memberof GetAllPublishers200Response
     */
    'items'?: Array<PublisherDto>;
}
/**
 * 
 * @export
 * @interface GetCountryList401Response
 */
export interface GetCountryList401Response {
    /**
     * 
     * @type {string}
     * @memberof GetCountryList401Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCountryList401Response
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface GetCountryList403Response
 */
export interface GetCountryList403Response {
    /**
     * 
     * @type {string}
     * @memberof GetCountryList403Response
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCountryList403Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCountryList403Response
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface GetCountryList500Response
 */
export interface GetCountryList500Response {
    /**
     * 
     * @type {string}
     * @memberof GetCountryList500Response
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCountryList500Response
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCountryList500Response
     */
    'detail'?: string;
}
/**
 * 
 * @export
 * @interface GetProductsList200Response
 */
export interface GetProductsList200Response {
    /**
     * 
     * @type {number}
     * @memberof GetProductsList200Response
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsList200Response
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsList200Response
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof GetProductsList200Response
     */
    'items'?: Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface GetProductsListForCampaign200Response
 */
export interface GetProductsListForCampaign200Response {
    /**
     * 
     * @type {number}
     * @memberof GetProductsListForCampaign200Response
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsListForCampaign200Response
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsListForCampaign200Response
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<ProductCampaign>}
     * @memberof GetProductsListForCampaign200Response
     */
    'items'?: Array<ProductCampaign>;
}
/**
 * 
 * @export
 * @interface GetProductsListWithLinksBySource200Response
 */
export interface GetProductsListWithLinksBySource200Response {
    /**
     * 
     * @type {number}
     * @memberof GetProductsListWithLinksBySource200Response
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsListWithLinksBySource200Response
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProductsListWithLinksBySource200Response
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<ProductCampaignDto>}
     * @memberof GetProductsListWithLinksBySource200Response
     */
    'items'?: Array<ProductCampaignDto>;
}
/**
 * 
 * @export
 * @interface LegalInfo
 */
export interface LegalInfo {
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'id': string;
    /**
     * 
     * @type {SellerUser}
     * @memberof LegalInfo
     */
    'user'?: SellerUser | null;
    /**
     * 
     * @type {LegalInfoCountry}
     * @memberof LegalInfo
     */
    'country'?: LegalInfoCountry | null;
    /**
     * 
     * @type {LegalTypeEnum3}
     * @memberof LegalInfo
     */
    'legalType'?: LegalTypeEnum3;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'inn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'taxNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'mobilePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LegalInfo
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalInfo
     */
    'deleted': boolean;
}


/**
 * @type LegalInfoCountry
 * @export
 */
export type LegalInfoCountry = Country;

/**
 * 
 * @export
 * @interface LegalInfoDto
 */
export interface LegalInfoDto {
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto
     */
    'country': string;
    /**
     * 
     * @type {LegalInfoDtoLegalType}
     * @memberof LegalInfoDto
     */
    'legal_type': LegalInfoDtoLegalType | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto
     */
    'inn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto
     */
    'tax_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto
     */
    'mobile_phone'?: string | null;
}
/**
 * 
 * @export
 * @interface LegalInfoDto2
 */
export interface LegalInfoDto2 {
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'country': string;
    /**
     * 
     * @type {LegalInfoDto2LegalType}
     * @memberof LegalInfoDto2
     */
    'legal_type': LegalInfoDto2LegalType | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'inn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'tax_number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LegalInfoDto2
     */
    'mobile_phone'?: string | null;
}
/**
 * @type LegalInfoDto2LegalType
 * @export
 */
export type LegalInfoDto2LegalType = LegalTypeEnum2;

/**
 * @type LegalInfoDtoLegalType
 * @export
 */
export type LegalInfoDtoLegalType = LegalTypeEnum;

/**
 * 
 * @export
 * @enum {string}
 */

export const LegalTypeEnum = {
    NaturalPerson: 'natural_person',
    IndividualEntrepreneur: 'individual_entrepreneur',
    Company: 'company'
} as const;

export type LegalTypeEnum = typeof LegalTypeEnum[keyof typeof LegalTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const LegalTypeEnum2 = {
    NaturalPerson: 'natural_person',
    IndividualEntrepreneur: 'individual_entrepreneur',
    Company: 'company'
} as const;

export type LegalTypeEnum2 = typeof LegalTypeEnum2[keyof typeof LegalTypeEnum2];


/**
 * 
 * @export
 * @enum {string}
 */

export const LegalTypeEnum3 = {
    NaturalPerson: 'natural_person',
    IndividualEntrepreneur: 'individual_entrepreneur',
    Company: 'company'
} as const;

export type LegalTypeEnum3 = typeof LegalTypeEnum3[keyof typeof LegalTypeEnum3];


/**
 * 
 * @export
 * @interface LoginRequestDto
 */
export interface LoginRequestDto {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPlaceSlugEnum = {
    Ozon: 'ozon',
    Wildberries: 'wildberries'
} as const;

export type MarketPlaceSlugEnum = typeof MarketPlaceSlugEnum[keyof typeof MarketPlaceSlugEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPlaceSlugEnum2 = {
    Ozon: 'ozon',
    Wildberries: 'wildberries'
} as const;

export type MarketPlaceSlugEnum2 = typeof MarketPlaceSlugEnum2[keyof typeof MarketPlaceSlugEnum2];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPlaceSlugEnum3 = {
    Ozon: 'ozon',
    Wildberries: 'wildberries'
} as const;

export type MarketPlaceSlugEnum3 = typeof MarketPlaceSlugEnum3[keyof typeof MarketPlaceSlugEnum3];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketPlaceSlugEnum4 = {
    Ozon: 'ozon',
    Wildberries: 'wildberries'
} as const;

export type MarketPlaceSlugEnum4 = typeof MarketPlaceSlugEnum4[keyof typeof MarketPlaceSlugEnum4];


/**
 * 
 * @export
 * @interface Marketplace
 */
export interface Marketplace {
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'title': string;
    /**
     * 
     * @type {MarketPlaceSlugEnum3}
     * @memberof Marketplace
     */
    'slug': MarketPlaceSlugEnum3;
    /**
     * 
     * @type {string}
     * @memberof Marketplace
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface Marketplace2
 */
export interface Marketplace2 {
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'title': string;
    /**
     * 
     * @type {MarketPlaceSlugEnum4}
     * @memberof Marketplace2
     */
    'slug': MarketPlaceSlugEnum4;
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Marketplace2
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Marketplace2
     */
    'deleted': boolean;
}


/**
 * 
 * @export
 * @interface MarketplaceDto
 */
export interface MarketplaceDto {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface MarketplaceIntegrationDto
 */
export interface MarketplaceIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceIntegrationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceIntegrationDto
     */
    'store_id': string;
    /**
     * 
     * @type {MarketPlaceSlugEnum2}
     * @memberof MarketplaceIntegrationDto
     */
    'marketplace_slug': MarketPlaceSlugEnum2;
}


/**
 * 
 * @export
 * @interface MarketplaceIntegrationListDto
 */
export interface MarketplaceIntegrationListDto {
    /**
     * 
     * @type {Array<MarketplaceIntegrationDto>}
     * @memberof MarketplaceIntegrationListDto
     */
    'items'?: Array<MarketplaceIntegrationDto>;
}
/**
 * 
 * @export
 * @interface MarketplaceListDto
 */
export interface MarketplaceListDto {
    /**
     * 
     * @type {Array<MarketplaceDto>}
     * @memberof MarketplaceListDto
     */
    'items'?: Array<MarketplaceDto>;
}
/**
 * 
 * @export
 * @interface MarketplaceStore
 */
export interface MarketplaceStore {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface MarketplaceStore2
 */
export interface MarketplaceStore2 {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore2
     */
    'id': string;
    /**
     * 
     * @type {MarketplaceStore2Store}
     * @memberof MarketplaceStore2
     */
    'store'?: MarketplaceStore2Store | null;
    /**
     * 
     * @type {MarketplaceStore2Marketplace}
     * @memberof MarketplaceStore2
     */
    'marketplace'?: MarketplaceStore2Marketplace | null;
}
/**
 * @type MarketplaceStore2Marketplace
 * @export
 */
export type MarketplaceStore2Marketplace = Marketplace;

/**
 * @type MarketplaceStore2Store
 * @export
 */
export type MarketplaceStore2Store = Store2;

/**
 * 
 * @export
 * @interface MarketplaceStore3
 */
export interface MarketplaceStore3 {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MarketplaceStore3
     */
    'settings'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'id': string;
    /**
     * 
     * @type {MarketplaceStore3Store}
     * @memberof MarketplaceStore3
     */
    'store'?: MarketplaceStore3Store | null;
    /**
     * 
     * @type {MarketplaceStore3Marketplace}
     * @memberof MarketplaceStore3
     */
    'marketplace'?: MarketplaceStore3Marketplace | null;
    /**
     * 
     * @type {MarketplaceStoreStatusEnum3}
     * @memberof MarketplaceStore3
     */
    'status'?: MarketplaceStoreStatusEnum3;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'successfulProductsSyncDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'successfulAnalyticsSyncDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStore3
     */
    'secretNamespace': string;
}


/**
 * @type MarketplaceStore3Marketplace
 * @export
 */
export type MarketplaceStore3Marketplace = Marketplace2;

/**
 * @type MarketplaceStore3Store
 * @export
 */
export type MarketplaceStore3Store = Store;

/**
 * 
 * @export
 * @interface MarketplaceStoreDto
 */
export interface MarketplaceStoreDto {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto
     */
    'marketplace_title': string;
    /**
     * 
     * @type {MarketPlaceSlugEnum}
     * @memberof MarketplaceStoreDto
     */
    'marketplace_slug': MarketPlaceSlugEnum;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto
     */
    'storeTitle'?: string | null;
    /**
     * 
     * @type {MarketplaceStoreStatusEnum}
     * @memberof MarketplaceStoreDto
     */
    'status': MarketplaceStoreStatusEnum;
}


/**
 * 
 * @export
 * @interface MarketplaceStoreDto2
 */
export interface MarketplaceStoreDto2 {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto2
     */
    'marketplace_title': string;
    /**
     * 
     * @type {MarketPlaceSlugEnum3}
     * @memberof MarketplaceStoreDto2
     */
    'marketplace_slug': MarketPlaceSlugEnum3;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceStoreDto2
     */
    'storeTitle'?: string | null;
    /**
     * 
     * @type {MarketplaceStoreStatusEnum2}
     * @memberof MarketplaceStoreDto2
     */
    'status': MarketplaceStoreStatusEnum2;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketplaceStoreStatusEnum = {
    Connected: 'connected',
    Disconnected: 'disconnected'
} as const;

export type MarketplaceStoreStatusEnum = typeof MarketplaceStoreStatusEnum[keyof typeof MarketplaceStoreStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketplaceStoreStatusEnum2 = {
    Connected: 'connected',
    Disconnected: 'disconnected'
} as const;

export type MarketplaceStoreStatusEnum2 = typeof MarketplaceStoreStatusEnum2[keyof typeof MarketplaceStoreStatusEnum2];


/**
 * 
 * @export
 * @enum {string}
 */

export const MarketplaceStoreStatusEnum3 = {
    Connected: 'connected',
    Disconnected: 'disconnected'
} as const;

export type MarketplaceStoreStatusEnum3 = typeof MarketplaceStoreStatusEnum3[keyof typeof MarketplaceStoreStatusEnum3];


/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {SenderDto}
     * @memberof MessageDto
     */
    'sender': SenderDto;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface MessageDto2
 */
export interface MessageDto2 {
    /**
     * 
     * @type {string}
     * @memberof MessageDto2
     */
    'id': string;
    /**
     * 
     * @type {SenderDto2}
     * @memberof MessageDto2
     */
    'sender': SenderDto2;
    /**
     * 
     * @type {string}
     * @memberof MessageDto2
     */
    'body': string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto2
     */
    'isRead'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageDto2
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface MessageDtoList
 */
export interface MessageDtoList {
    /**
     * 
     * @type {Array<MessageDto2>}
     * @memberof MessageDtoList
     */
    'items'?: Array<MessageDto2>;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {NotificationDtoCreatedBy}
     * @memberof NotificationDto
     */
    'createdBy': NotificationDtoCreatedBy | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'body': string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'read': boolean;
}
/**
 * 
 * @export
 * @interface NotificationDto2
 */
export interface NotificationDto2 {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto2
     */
    'id': string;
    /**
     * 
     * @type {NotificationDto2CreatedBy}
     * @memberof NotificationDto2
     */
    'createdBy': NotificationDto2CreatedBy | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto2
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto2
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto2
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto2
     */
    'read': boolean;
}
/**
 * @type NotificationDto2CreatedBy
 * @export
 */
export type NotificationDto2CreatedBy = UserDto;

/**
 * @type NotificationDtoCreatedBy
 * @export
 */
export type NotificationDtoCreatedBy = object;

/**
 * 
 * @export
 * @interface NotificationDtoList
 */
export interface NotificationDtoList {
    /**
     * 
     * @type {Array<NotificationDto2>}
     * @memberof NotificationDtoList
     */
    'items'?: Array<NotificationDto2>;
}
/**
 * 
 * @export
 * @interface OzonIntegrationDto
 */
export interface OzonIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof OzonIntegrationDto
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof OzonIntegrationDto
     */
    'seller_client_id': string;
    /**
     * 
     * @type {string}
     * @memberof OzonIntegrationDto
     */
    'seller_api_key': string;
    /**
     * 
     * @type {string}
     * @memberof OzonIntegrationDto
     */
    'performance_client_id': string;
    /**
     * 
     * @type {string}
     * @memberof OzonIntegrationDto
     */
    'performance_api_key': string;
}
/**
 * 
 * @export
 * @interface PaginationRequest
 */
export interface PaginationRequest {
    /**
     * 
     * @type {number}
     * @memberof PaginationRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationRequest
     */
    'limit'?: number;
}
/**
 * 
 * @export
 * @interface ParamsIntegrationDto
 */
export interface ParamsIntegrationDto {
    /**
     * 
     * @type {boolean}
     * @memberof ParamsIntegrationDto
     */
    'create_campaign_draft'?: boolean;
}
/**
 * 
 * @export
 * @interface PaymentType
 */
export interface PaymentType {
    /**
     * 
     * @type {number}
     * @memberof PaymentType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PaymentType
     */
    'title': string;
    /**
     * 
     * @type {PaymentTypeEnum}
     * @memberof PaymentType
     */
    'type': PaymentTypeEnum;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentTypeEnum = {
    Cpa: 'cpa',
    Cpp: 'cpp',
    Barter: 'barter'
} as const;

export type PaymentTypeEnum = typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];


/**
 * 
 * @export
 * @interface PaymentTypeList
 */
export interface PaymentTypeList {
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof PaymentTypeList
     */
    'items'?: Array<PaymentType>;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {CampaignDto2MarketplaceStore}
     * @memberof Product
     */
    'marketplace_store'?: CampaignDto2MarketplaceStore | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'price': string;
    /**
     * 
     * @type {ProductDtoCurrency}
     * @memberof Product
     */
    'currency'?: ProductDtoCurrency | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'external_link': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'external_id': string;
    /**
     * 
     * @type {ProductDto2ProductCategory}
     * @memberof Product
     */
    'product_category'?: ProductDto2ProductCategory | null;
}
/**
 * 
 * @export
 * @interface ProductCampaign
 */
export interface ProductCampaign {
    /**
     * 
     * @type {string}
     * @memberof ProductCampaign
     */
    'id': string;
    /**
     * 
     * @type {Campaign}
     * @memberof ProductCampaign
     */
    'campaign': Campaign;
    /**
     * 
     * @type {Product}
     * @memberof ProductCampaign
     */
    'product': Product;
    /**
     * 
     * @type {CampaignDto2FeeSetting}
     * @memberof ProductCampaign
     */
    'fee_setting'?: CampaignDto2FeeSetting | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCampaign
     */
    'visible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCampaign
     */
    'favorite'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductCampaignDto
 */
export interface ProductCampaignDto {
    /**
     * 
     * @type {string}
     * @memberof ProductCampaignDto
     */
    'id': string;
    /**
     * 
     * @type {ProductCampaignDtoCampaign}
     * @memberof ProductCampaignDto
     */
    'campaign'?: ProductCampaignDtoCampaign | null;
    /**
     * 
     * @type {ProductCampaignDtoProduct}
     * @memberof ProductCampaignDto
     */
    'product'?: ProductCampaignDtoProduct | null;
    /**
     * 
     * @type {ProductCampaignDtoFeeSetting}
     * @memberof ProductCampaignDto
     */
    'fee_setting'?: ProductCampaignDtoFeeSetting | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCampaignDto
     */
    'visible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCampaignDto
     */
    'favorite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCampaignDto
     */
    'tracking_link': string;
}
/**
 * @type ProductCampaignDtoCampaign
 * @export
 */
export type ProductCampaignDtoCampaign = CampaignDto3;

/**
 * @type ProductCampaignDtoFeeSetting
 * @export
 */
export type ProductCampaignDtoFeeSetting = FeeSettingDto;

/**
 * @type ProductCampaignDtoProduct
 * @export
 */
export type ProductCampaignDtoProduct = ProductDto2;

/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface ProductCategoryDto
 */
export interface ProductCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryDto
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategoryDto
     */
    'title': string | null;
}
/**
 * 
 * @export
 * @interface ProductCategoryDtoList
 */
export interface ProductCategoryDtoList {
    /**
     * 
     * @type {Array<ProductCategoryDto>}
     * @memberof ProductCategoryDtoList
     */
    'items'?: Array<ProductCategoryDto>;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id': string;
    /**
     * 
     * @type {CampaignDto2MarketplaceStore}
     * @memberof ProductDto
     */
    'marketplace_store': CampaignDto2MarketplaceStore | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'price': number | null;
    /**
     * 
     * @type {ProductDtoCurrency}
     * @memberof ProductDto
     */
    'currency': ProductDtoCurrency | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'external_link': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'external_id': string;
}
/**
 * 
 * @export
 * @interface ProductDto2
 */
export interface ProductDto2 {
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'id': string;
    /**
     * 
     * @type {CampaignDto2MarketplaceStore}
     * @memberof ProductDto2
     */
    'marketplace_store': CampaignDto2MarketplaceStore | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto2
     */
    'price': number | null;
    /**
     * 
     * @type {ProductDtoCurrency}
     * @memberof ProductDto2
     */
    'currency': ProductDtoCurrency | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'external_link': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto2
     */
    'external_id': string;
    /**
     * 
     * @type {ProductDto2ProductCategory}
     * @memberof ProductDto2
     */
    'product_category': ProductDto2ProductCategory | null;
}
/**
 * @type ProductDto2ProductCategory
 * @export
 */
export type ProductDto2ProductCategory = ProductCategory;

/**
 * @type ProductDtoCurrency
 * @export
 */
export type ProductDtoCurrency = Currency;

/**
 * 
 * @export
 * @interface ProductFilterDto
 */
export interface ProductFilterDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterDto
     */
    'stores'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterDto
     */
    'marketplaces'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductFilterDto
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterDto
     */
    'bids'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFilterDto
     */
    'favorite'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductFilterDto2
 */
export interface ProductFilterDto2 {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterDto2
     */
    'stores'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductFilterDto2
     */
    'categories'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilterDto2
     */
    'marketplaces'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Publisher
 */
export interface Publisher {
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    'publisherVerificationCode'?: string;
    /**
     * 
     * @type {SellerUser}
     * @memberof Publisher
     */
    'user'?: SellerUser | null;
    /**
     * 
     * @type {SellerLegalInfo}
     * @memberof Publisher
     */
    'legalInfo'?: SellerLegalInfo | null;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Publisher
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Publisher
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface PublisherDto
 */
export interface PublisherDto {
    /**
     * 
     * @type {string}
     * @memberof PublisherDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublisherDto
     */
    'publisherVerificationCode': string;
    /**
     * 
     * @type {string}
     * @memberof PublisherDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PublisherDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PublisherDto
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {PublisherDtoLegalInfo}
     * @memberof PublisherDto
     */
    'legalInfo'?: PublisherDtoLegalInfo | null;
}
/**
 * @type PublisherDtoLegalInfo
 * @export
 */
export type PublisherDtoLegalInfo = LegalInfoDto2;

/**
 * 
 * @export
 * @enum {string}
 */

export const RevenueRangeEnum = {
    FirstLevel: 'first_level',
    SecondLevel: 'second_level',
    ThirdLevel: 'third_level',
    FourthLevel: 'fourth_level'
} as const;

export type RevenueRangeEnum = typeof RevenueRangeEnum[keyof typeof RevenueRangeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const RevenueRangeEnum2 = {
    FirstLevel: 'first_level',
    SecondLevel: 'second_level',
    ThirdLevel: 'third_level',
    FourthLevel: 'fourth_level'
} as const;

export type RevenueRangeEnum2 = typeof RevenueRangeEnum2[keyof typeof RevenueRangeEnum2];


/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'id': string;
    /**
     * 
     * @type {SellerUser}
     * @memberof Seller
     */
    'user'?: SellerUser | null;
    /**
     * 
     * @type {Array<Store>}
     * @memberof Seller
     */
    'stores': Array<Store>;
    /**
     * 
     * @type {SellerSellerInfo}
     * @memberof Seller
     */
    'sellerInfo'?: SellerSellerInfo | null;
    /**
     * 
     * @type {SellerLegalInfo}
     * @memberof Seller
     */
    'legalInfo'?: SellerLegalInfo | null;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'erid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface SellerDto
 */
export interface SellerDto {
    /**
     * 
     * @type {string}
     * @memberof SellerDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {PublisherDtoLegalInfo}
     * @memberof SellerDto
     */
    'legalInfo'?: PublisherDtoLegalInfo | null;
}
/**
 * 
 * @export
 * @interface SellerInfo
 */
export interface SellerInfo {
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    'website'?: string | null;
    /**
     * 
     * @type {SellersInfoDtoRevenueRange}
     * @memberof SellerInfo
     */
    'revenue'?: SellersInfoDtoRevenueRange | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SellerInfo
     */
    'sellersGoal'?: Array<SellerInfoSellersGoalEnum>;
}

export const SellerInfoSellersGoalEnum = {
    IncreaseSales: 'increase_sales',
    ObtainingStatistics: 'obtaining_statistics',
    OrderTracking: 'order_tracking',
    Analytics: 'analytics',
    CommunicationWithWebmasters: 'communication_with_webmasters',
    IncreasingReviews: 'increasing_reviews',
    Other: 'other'
} as const;

export type SellerInfoSellersGoalEnum = typeof SellerInfoSellersGoalEnum[keyof typeof SellerInfoSellersGoalEnum];

/**
 * @type SellerLegalInfo
 * @export
 */
export type SellerLegalInfo = LegalInfo;

/**
 * @type SellerSellerInfo
 * @export
 */
export type SellerSellerInfo = SellerInfo;

/**
 * @type SellerUser
 * @export
 */
export type SellerUser = User;

/**
 * 
 * @export
 * @interface SellersInfoDto
 */
export interface SellersInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SellersInfoDto
     */
    'website'?: string | null;
    /**
     * 
     * @type {SellersInfoDtoRevenueRange}
     * @memberof SellersInfoDto
     */
    'revenue_range'?: SellersInfoDtoRevenueRange | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SellersInfoDto
     */
    'sellers_goal'?: Array<SellersInfoDtoSellersGoalEnum>;
}

export const SellersInfoDtoSellersGoalEnum = {
    IncreaseSales: 'increase_sales',
    ObtainingStatistics: 'obtaining_statistics',
    OrderTracking: 'order_tracking',
    Analytics: 'analytics',
    CommunicationWithWebmasters: 'communication_with_webmasters',
    IncreasingReviews: 'increasing_reviews',
    Other: 'other'
} as const;

export type SellersInfoDtoSellersGoalEnum = typeof SellersInfoDtoSellersGoalEnum[keyof typeof SellersInfoDtoSellersGoalEnum];

/**
 * 
 * @export
 * @interface SellersInfoDto2
 */
export interface SellersInfoDto2 {
    /**
     * 
     * @type {string}
     * @memberof SellersInfoDto2
     */
    'website'?: string | null;
    /**
     * 
     * @type {SellersInfoDto2RevenueRange}
     * @memberof SellersInfoDto2
     */
    'revenue_range'?: SellersInfoDto2RevenueRange | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SellersInfoDto2
     */
    'sellers_goal'?: Array<SellersInfoDto2SellersGoalEnum>;
}

export const SellersInfoDto2SellersGoalEnum = {
    IncreaseSales: 'increase_sales',
    ObtainingStatistics: 'obtaining_statistics',
    OrderTracking: 'order_tracking',
    Analytics: 'analytics',
    CommunicationWithWebmasters: 'communication_with_webmasters',
    IncreasingReviews: 'increasing_reviews',
    Other: 'other'
} as const;

export type SellersInfoDto2SellersGoalEnum = typeof SellersInfoDto2SellersGoalEnum[keyof typeof SellersInfoDto2SellersGoalEnum];

/**
 * @type SellersInfoDto2RevenueRange
 * @export
 */
export type SellersInfoDto2RevenueRange = RevenueRangeEnum2;

/**
 * @type SellersInfoDtoRevenueRange
 * @export
 */
export type SellersInfoDtoRevenueRange = RevenueRangeEnum;

/**
 * 
 * @export
 * @interface SenderDto
 */
export interface SenderDto {
    /**
     * 
     * @type {string}
     * @memberof SenderDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SenderDto2
 */
export interface SenderDto2 {
    /**
     * 
     * @type {string}
     * @memberof SenderDto2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SenderDto2
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SenderDto2
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SenderDto2
     */
    'lastname'?: string | null;
}
/**
 * 
 * @export
 * @interface SetVisibilityDto
 */
export interface SetVisibilityDto {
    /**
     * 
     * @type {boolean}
     * @memberof SetVisibilityDto
     */
    'select_all'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetVisibilityDto
     */
    'visible'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetVisibilityDto
     */
    'products'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SourceCampaignFee
 */
export interface SourceCampaignFee {
    /**
     * 
     * @type {string}
     * @memberof SourceCampaignFee
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SourceContentType
 */
export interface SourceContentType {
    /**
     * 
     * @type {number}
     * @memberof SourceContentType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SourceContentType
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SourceContentType
     */
    'slug': string;
}
/**
 * 
 * @export
 * @interface SourceContentTypeList
 */
export interface SourceContentTypeList {
    /**
     * 
     * @type {Array<SourceContentType>}
     * @memberof SourceContentTypeList
     */
    'items'?: Array<SourceContentType>;
}
/**
 * 
 * @export
 * @interface SourceDto
 */
export interface SourceDto {
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    'title': string | null;
    /**
     * 
     * @type {number}
     * @memberof SourceDto
     */
    'source_type': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SourceDto
     */
    'source_content_types'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SourceDto
     */
    'source_url': string | null;
}
/**
 * 
 * @export
 * @interface SourceDto2
 */
export interface SourceDto2 {
    /**
     * 
     * @type {string}
     * @memberof SourceDto2
     */
    'title': string | null;
    /**
     * 
     * @type {number}
     * @memberof SourceDto2
     */
    'source_type': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SourceDto2
     */
    'source_content_types'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SourceDto2
     */
    'source_url': string | null;
}
/**
 * 
 * @export
 * @interface SourceDto3
 */
export interface SourceDto3 {
    /**
     * 
     * @type {string}
     * @memberof SourceDto3
     */
    'id'?: string | null;
    /**
     * 
     * @type {SourceDto3Publisher}
     * @memberof SourceDto3
     */
    'publisher'?: SourceDto3Publisher | null;
    /**
     * 
     * @type {string}
     * @memberof SourceDto3
     */
    'title': string | null;
    /**
     * 
     * @type {SourceDto3Status}
     * @memberof SourceDto3
     */
    'status'?: SourceDto3Status | null;
    /**
     * 
     * @type {number}
     * @memberof SourceDto3
     */
    'source_type': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SourceDto3
     */
    'source_content_types'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SourceDto3
     */
    'source_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof SourceDto3
     */
    'comment'?: string | null;
}
/**
 * @type SourceDto3Publisher
 * @export
 */
export type SourceDto3Publisher = object;

/**
 * @type SourceDto3Status
 * @export
 */
export type SourceDto3Status = SourceStatusEnum;

/**
 * 
 * @export
 * @interface SourceDtoList
 */
export interface SourceDtoList {
    /**
     * 
     * @type {Array<SourceDto3>}
     * @memberof SourceDtoList
     */
    'items'?: Array<SourceDto3>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SourceStatusEnum = {
    Verification: 'verification',
    Approved: 'approved',
    Declined: 'declined'
} as const;

export type SourceStatusEnum = typeof SourceStatusEnum[keyof typeof SourceStatusEnum];


/**
 * 
 * @export
 * @interface SourceType
 */
export interface SourceType {
    /**
     * 
     * @type {number}
     * @memberof SourceType
     */
    'id': number;
    /**
     * 
     * @type {SourceTypeEnum}
     * @memberof SourceType
     */
    'type': SourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SourceType
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SourceType
     */
    'slug': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SourceTypeEnum = {
    SocialNetwork: 'social_network',
    Messenger: 'messenger',
    Other: 'other'
} as const;

export type SourceTypeEnum = typeof SourceTypeEnum[keyof typeof SourceTypeEnum];


/**
 * 
 * @export
 * @interface SourceTypeList
 */
export interface SourceTypeList {
    /**
     * 
     * @type {Array<SourceType>}
     * @memberof SourceTypeList
     */
    'items'?: Array<SourceType>;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'id': string;
    /**
     * 
     * @type {UserSeller}
     * @memberof Store
     */
    'seller'?: UserSeller | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'description': string;
    /**
     * 
     * @type {Array<MarketplaceStore3>}
     * @memberof Store
     */
    'marketplaceStores': Array<MarketplaceStore3>;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    'updatedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface Store2
 */
export interface Store2 {
    /**
     * 
     * @type {string}
     * @memberof Store2
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Store2
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Store2
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Store2
     */
    'logo'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreDto
 */
export interface StoreDto {
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreDto
     */
    'logo'?: string | null;
}
/**
 * 
 * @export
 * @interface StoreDto2
 */
export interface StoreDto2 {
    /**
     * 
     * @type {string}
     * @memberof StoreDto2
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto2
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto2
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreDto2
     */
    'logo'?: string | null;
    /**
     * 
     * @type {Array<MarketplaceStoreDto>}
     * @memberof StoreDto2
     */
    'marketplaces'?: Array<MarketplaceStoreDto>;
}
/**
 * 
 * @export
 * @interface StoreDto3
 */
export interface StoreDto3 {
    /**
     * 
     * @type {string}
     * @memberof StoreDto3
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto3
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto3
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof StoreDto3
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StoreDto3
     */
    'logo'?: string | null;
    /**
     * 
     * @type {Array<MarketplaceStoreDto2>}
     * @memberof StoreDto3
     */
    'marketplaces'?: Array<MarketplaceStoreDto2>;
    /**
     * 
     * @type {boolean}
     * @memberof StoreDto3
     */
    'active': boolean;
}
/**
 * 
 * @export
 * @interface StoreDtoList
 */
export interface StoreDtoList {
    /**
     * 
     * @type {Array<StoreDto3>}
     * @memberof StoreDtoList
     */
    'items'?: Array<StoreDto3>;
}
/**
 * 
 * @export
 * @interface ThreadDto
 */
export interface ThreadDto {
    /**
     * 
     * @type {string}
     * @memberof ThreadDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto
     */
    'subject': string;
    /**
     * 
     * @type {ThreadMemberDto}
     * @memberof ThreadDto
     */
    'holder': ThreadMemberDto;
    /**
     * 
     * @type {ThreadMemberDto}
     * @memberof ThreadDto
     */
    'member': ThreadMemberDto;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ThreadDto2
 */
export interface ThreadDto2 {
    /**
     * 
     * @type {string}
     * @memberof ThreadDto2
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto2
     */
    'subject': string;
    /**
     * 
     * @type {ThreadMemberDto2}
     * @memberof ThreadDto2
     */
    'holder': ThreadMemberDto2;
    /**
     * 
     * @type {ThreadMemberDto2}
     * @memberof ThreadDto2
     */
    'member': ThreadMemberDto2;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto2
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface ThreadDto3
 */
export interface ThreadDto3 {
    /**
     * 
     * @type {string}
     * @memberof ThreadDto3
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto3
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto3
     */
    'subject': string;
    /**
     * 
     * @type {ThreadMemberDto3}
     * @memberof ThreadDto3
     */
    'holder': ThreadMemberDto3;
    /**
     * 
     * @type {ThreadMemberDto3}
     * @memberof ThreadDto3
     */
    'member': ThreadMemberDto3;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto3
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof ThreadDto3
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ThreadDtoList
 */
export interface ThreadDtoList {
    /**
     * 
     * @type {Array<ThreadDto3>}
     * @memberof ThreadDtoList
     */
    'items'?: Array<ThreadDto3>;
}
/**
 * 
 * @export
 * @interface ThreadMemberDto
 */
export interface ThreadMemberDto {
    /**
     * 
     * @type {string}
     * @memberof ThreadMemberDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadMemberDto
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface ThreadMemberDto2
 */
export interface ThreadMemberDto2 {
    /**
     * 
     * @type {string}
     * @memberof ThreadMemberDto2
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadMemberDto2
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface ThreadMemberDto3
 */
export interface ThreadMemberDto3 {
    /**
     * 
     * @type {string}
     * @memberof ThreadMemberDto3
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ThreadMemberDto3
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {UserSeller}
     * @memberof User
     */
    'seller'?: UserSeller | null;
    /**
     * 
     * @type {UserPublisher}
     * @memberof User
     */
    'publisher'?: UserPublisher | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mitgo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'onboarded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tapfiliateCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tapfiliateRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'balance'?: string;
    /**
     * 
     * @type {UserCredit}
     * @memberof User
     */
    'credit'?: UserCredit | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'role': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fullName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userType': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'deleted': boolean;
}
/**
 * @type UserCredit
 * @export
 */
export type UserCredit = Credit;

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {UserDtoSeller}
     * @memberof UserDto
     */
    'seller'?: UserDtoSeller | null;
    /**
     * 
     * @type {UserDtoPublisher}
     * @memberof UserDto
     */
    'publisher'?: UserDtoPublisher | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'avatar'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'onboarded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string | null;
}
/**
 * @type UserDtoPublisher
 * @export
 */
export type UserDtoPublisher = PublisherDto;

/**
 * @type UserDtoSeller
 * @export
 */
export type UserDtoSeller = SellerDto;

/**
 * @type UserPublisher
 * @export
 */
export type UserPublisher = Publisher;

/**
 * 
 * @export
 * @interface UserRegistrationDto
 */
export interface UserRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRegistrationDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRegistrationDto
     */
    'isSeller'?: boolean;
}
/**
 * 
 * @export
 * @interface UserRolesDto
 */
export interface UserRolesDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserRolesDto
     */
    'is_publisher'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRolesDto
     */
    'is_seller'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRolesDto
     */
    'publisher'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRolesDto
     */
    'seller'?: boolean | null;
}
/**
 * @type UserSeller
 * @export
 */
export type UserSeller = Seller;

/**
 * 
 * @export
 * @interface ViewPublisherLegalInfo404Response
 */
export interface ViewPublisherLegalInfo404Response {
    /**
     * 
     * @type {string}
     * @memberof ViewPublisherLegalInfo404Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ViewPublisherLegalInfo404Response
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface WildberriesIntegrationDto
 */
export interface WildberriesIntegrationDto {
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'validation_key': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'seller_device_id': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'authorize_v3_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto
     */
    'api_authorization_token': string | null;
}
/**
 * 
 * @export
 * @interface WildberriesIntegrationDto2
 */
export interface WildberriesIntegrationDto2 {
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'validation_key': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'seller_device_id': string;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'authorize_v3_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WildberriesIntegrationDto2
     */
    'api_authorization_token': string | null;
}

/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/country/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountryList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountryList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CountriesApi.getCountryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryList(options?: RawAxiosRequestConfig): AxiosPromise<CountryListDto> {
            return localVarFp.getCountryList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public getCountryList(options?: RawAxiosRequestConfig) {
        return CountriesApiFp(this.configuration).getCountryList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadANewFile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/file/upload/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadANewFile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadANewFile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadANewFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadANewFile(options?: RawAxiosRequestConfig): AxiosPromise<FileDto> {
            return localVarFp.uploadANewFile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadANewFile(options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadANewFile(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewMessageInThread: async (threadId: string, messageDto: MessageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('createNewMessageInThread', 'threadId', threadId)
            // verify required parameter 'messageDto' is not null or undefined
            assertParamExists('createNewMessageInThread', 'messageDto', messageDto)
            const localVarPath = `/api/seller/v1/messenger/threads/{threadId}/messages/`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfMessagesForSeller: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getCollectionOfMessagesForSeller', 'threadId', threadId)
            const localVarPath = `/api/seller/v1/messenger/threads/{threadId}/messages/`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewMessageInThread(threadId: string, messageDto: MessageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewMessageInThread(threadId, messageDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.createNewMessageInThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionOfMessagesForSeller(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionOfMessagesForSeller(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MessagesApi.getCollectionOfMessagesForSeller']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {MessageDto} messageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewMessageInThread(threadId: string, messageDto: MessageDto, options?: RawAxiosRequestConfig): AxiosPromise<MessageDto> {
            return localVarFp.createNewMessageInThread(threadId, messageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfMessagesForSeller(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<MessageDtoList> {
            return localVarFp.getCollectionOfMessagesForSeller(threadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {string} threadId 
     * @param {MessageDto} messageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public createNewMessageInThread(threadId: string, messageDto: MessageDto, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).createNewMessageInThread(threadId, messageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getCollectionOfMessagesForSeller(threadId: string, options?: RawAxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getCollectionOfMessagesForSeller(threadId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewNotification: async (notificationDto: NotificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationDto' is not null or undefined
            assertParamExists('createNewNotification', 'notificationDto', notificationDto)
            const localVarPath = `/api/seller/v1/messenger/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfReceivedNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/messenger/notification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewNotification(notificationDto: NotificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewNotification(notificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.createNewNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionOfReceivedNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionOfReceivedNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationApi.getCollectionOfReceivedNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationDto} notificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewNotification(notificationDto: NotificationDto, options?: RawAxiosRequestConfig): AxiosPromise<NotificationDto> {
            return localVarFp.createNewNotification(notificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfReceivedNotifications(options?: RawAxiosRequestConfig): AxiosPromise<NotificationDtoList> {
            return localVarFp.getCollectionOfReceivedNotifications(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {NotificationDto} notificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public createNewNotification(notificationDto: NotificationDto, options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).createNewNotification(notificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getCollectionOfReceivedNotifications(options?: RawAxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getCollectionOfReceivedNotifications(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetails: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductDetails', 'id', id)
            const localVarPath = `/api/seller/v1/product/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsList: async (page?: number, limit?: number, stores?: Array<string>, categories?: Array<number>, marketplaces?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/product/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (stores) {
                localVarQueryParameter['stores[]'] = stores;
            }

            if (categories) {
                localVarQueryParameter['categories[]'] = categories;
            }

            if (marketplaces) {
                localVarQueryParameter['marketplaces[]'] = marketplaces;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetails(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.getProductDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsList(page?: number, limit?: number, stores?: Array<string>, categories?: Array<number>, marketplaces?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsList(page, limit, stores, categories, marketplaces, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductApi.getProductsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetails(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductDto> {
            return localVarFp.getProductDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [marketplaces] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsList(page?: number, limit?: number, stores?: Array<string>, categories?: Array<number>, marketplaces?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsList200Response> {
            return localVarFp.getProductsList(page, limit, stores, categories, marketplaces, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductDetails(id: string, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {Array<string>} [stores] 
     * @param {Array<number>} [categories] 
     * @param {Array<string>} [marketplaces] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductsList(page?: number, limit?: number, stores?: Array<string>, categories?: Array<number>, marketplaces?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductsList(page, limit, stores, categories, marketplaces, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductCampaignFavoritesApi - axios parameter creator
 * @export
 */
export const ProductCampaignFavoritesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAProductCampaignToFavorites: async (productCampaign: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCampaign' is not null or undefined
            assertParamExists('addAProductCampaignToFavorites', 'productCampaign', productCampaign)
            const localVarPath = `/api/publisher/v1/product/{productCampaign}/favorites/`
                .replace(`{${"productCampaign"}}`, encodeURIComponent(String(productCampaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductCampaignFromFavorites: async (productCampaign: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productCampaign' is not null or undefined
            assertParamExists('removeProductCampaignFromFavorites', 'productCampaign', productCampaign)
            const localVarPath = `/api/publisher/v1/product/{productCampaign}/favorites/`
                .replace(`{${"productCampaign"}}`, encodeURIComponent(String(productCampaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCampaignFavoritesApi - functional programming interface
 * @export
 */
export const ProductCampaignFavoritesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCampaignFavoritesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAProductCampaignToFavorites(productCampaign: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAProductCampaignToFavorites(productCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCampaignFavoritesApi.addAProductCampaignToFavorites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeProductCampaignFromFavorites(productCampaign: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeProductCampaignFromFavorites(productCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCampaignFavoritesApi.removeProductCampaignFromFavorites']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductCampaignFavoritesApi - factory interface
 * @export
 */
export const ProductCampaignFavoritesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCampaignFavoritesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAProductCampaignToFavorites(productCampaign: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.addAProductCampaignToFavorites(productCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductCampaignFromFavorites(productCampaign: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeProductCampaignFromFavorites(productCampaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCampaignFavoritesApi - object-oriented interface
 * @export
 * @class ProductCampaignFavoritesApi
 * @extends {BaseAPI}
 */
export class ProductCampaignFavoritesApi extends BaseAPI {
    /**
     * 
     * @param {string} productCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCampaignFavoritesApi
     */
    public addAProductCampaignToFavorites(productCampaign: string, options?: RawAxiosRequestConfig) {
        return ProductCampaignFavoritesApiFp(this.configuration).addAProductCampaignToFavorites(productCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCampaignFavoritesApi
     */
    public removeProductCampaignFromFavorites(productCampaign: string, options?: RawAxiosRequestConfig) {
        return ProductCampaignFavoritesApiFp(this.configuration).removeProductCampaignFromFavorites(productCampaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProductCategoriesApi - axios parameter creator
 * @export
 */
export const ProductCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoriesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product-category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductCategoriesApi - functional programming interface
 * @export
 */
export const ProductCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductCategoriesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategoryDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductCategoriesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductCategoriesApi.getProductCategoriesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductCategoriesApi - factory interface
 * @export
 */
export const ProductCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductCategoriesList(options?: RawAxiosRequestConfig): AxiosPromise<ProductCategoryDtoList> {
            return localVarFp.getProductCategoriesList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductCategoriesApi - object-oriented interface
 * @export
 * @class ProductCategoriesApi
 * @extends {BaseAPI}
 */
export class ProductCategoriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductCategoriesApi
     */
    public getProductCategoriesList(options?: RawAxiosRequestConfig) {
        return ProductCategoriesApiFp(this.configuration).getProductCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublisherApi - axios parameter creator
 * @export
 */
export const PublisherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublishers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/publishers/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherApi - functional programming interface
 * @export
 */
export const PublisherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPublishers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPublishers200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPublishers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherApi.getAllPublishers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublisherApi - factory interface
 * @export
 */
export const PublisherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPublishers(options?: RawAxiosRequestConfig): AxiosPromise<GetAllPublishers200Response> {
            return localVarFp.getAllPublishers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublisherApi - object-oriented interface
 * @export
 * @class PublisherApi
 * @extends {BaseAPI}
 */
export class PublisherApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherApi
     */
    public getAllPublishers(options?: RawAxiosRequestConfig) {
        return PublisherApiFp(this.configuration).getAllPublishers(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublisherLegalInfoApi - axios parameter creator
 * @export
 */
export const PublisherLegalInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPublisherLegalInfo: async (legalInfoDto: LegalInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInfoDto' is not null or undefined
            assertParamExists('addPublisherLegalInfo', 'legalInfoDto', legalInfoDto)
            const localVarPath = `/api/publisher/v1/legal-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewPublisherLegalInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publisher/v1/legal-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherLegalInfoApi - functional programming interface
 * @export
 */
export const PublisherLegalInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherLegalInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPublisherLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPublisherLegalInfo(legalInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherLegalInfoApi.addPublisherLegalInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewPublisherLegalInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewPublisherLegalInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherLegalInfoApi.viewPublisherLegalInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublisherLegalInfoApi - factory interface
 * @export
 */
export const PublisherLegalInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherLegalInfoApiFp(configuration)
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPublisherLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig): AxiosPromise<LegalInfoDto2> {
            return localVarFp.addPublisherLegalInfo(legalInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewPublisherLegalInfo(options?: RawAxiosRequestConfig): AxiosPromise<LegalInfoDto2> {
            return localVarFp.viewPublisherLegalInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublisherLegalInfoApi - object-oriented interface
 * @export
 * @class PublisherLegalInfoApi
 * @extends {BaseAPI}
 */
export class PublisherLegalInfoApi extends BaseAPI {
    /**
     * 
     * @param {LegalInfoDto} legalInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherLegalInfoApi
     */
    public addPublisherLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig) {
        return PublisherLegalInfoApiFp(this.configuration).addPublisherLegalInfo(legalInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherLegalInfoApi
     */
    public viewPublisherLegalInfo(options?: RawAxiosRequestConfig) {
        return PublisherLegalInfoApiFp(this.configuration).viewPublisherLegalInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublisherSourcesApi - axios parameter creator
 * @export
 */
export const PublisherSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SourceDto} sourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addASource: async (sourceDto: SourceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceDto' is not null or undefined
            assertParamExists('addASource', 'sourceDto', sourceDto)
            const localVarPath = `/api/publisher/v1/source/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppPrivatePublisherSourceDelete: async (source: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('deleteAppPrivatePublisherSourceDelete', 'source', source)
            const localVarPath = `/api/publisher/v1/source/{source}/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivatePublisherSourceProductlistexport: async (source: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('getAppPrivatePublisherSourceProductlistexport', 'source', source)
            const localVarPath = `/api/publisher/v1/source/{source}/products/export/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsDetailsWithLinksBySource: async (source: string, productCampaign: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('getProductsDetailsWithLinksBySource', 'source', source)
            // verify required parameter 'productCampaign' is not null or undefined
            assertParamExists('getProductsDetailsWithLinksBySource', 'productCampaign', productCampaign)
            const localVarPath = `/api/publisher/v1/source/{source}/product/{productCampaign}/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)))
                .replace(`{${"productCampaign"}}`, encodeURIComponent(String(productCampaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<string>} [marketplaces] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [bids] 
         * @param {boolean} [favorite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsListWithLinksBySource: async (source: string, page?: number, limit?: number, stores?: Array<string>, marketplaces?: Array<string>, categories?: Array<number>, bids?: Array<string>, favorite?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('getProductsListWithLinksBySource', 'source', source)
            const localVarPath = `/api/publisher/v1/source/{source}/products/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (stores) {
                localVarQueryParameter['stores[]'] = stores;
            }

            if (marketplaces) {
                localVarQueryParameter['marketplaces[]'] = marketplaces;
            }

            if (categories) {
                localVarQueryParameter['categories[]'] = categories;
            }

            if (bids) {
                localVarQueryParameter['bids[]'] = bids;
            }

            if (favorite !== undefined) {
                localVarQueryParameter['favorite'] = favorite;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceContentTypesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publisher/v1/source-content-types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceTypesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publisher/v1/source-types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publisher/v1/source/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {SourceDto2} sourceDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivatePublisherSourceUpdate: async (source: string, sourceDto2: SourceDto2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('putAppPrivatePublisherSourceUpdate', 'source', source)
            // verify required parameter 'sourceDto2' is not null or undefined
            assertParamExists('putAppPrivatePublisherSourceUpdate', 'sourceDto2', sourceDto2)
            const localVarPath = `/api/publisher/v1/source/{source}/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceDto2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewASource: async (source: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'source' is not null or undefined
            assertParamExists('viewASource', 'source', source)
            const localVarPath = `/api/publisher/v1/source/{source}/`
                .replace(`{${"source"}}`, encodeURIComponent(String(source)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherSourcesApi - functional programming interface
 * @export
 */
export const PublisherSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SourceDto} sourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addASource(sourceDto: SourceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceDto3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addASource(sourceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.addASource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppPrivatePublisherSourceDelete(source: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppPrivatePublisherSourceDelete(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.deleteAppPrivatePublisherSourceDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPrivatePublisherSourceProductlistexport(source: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPrivatePublisherSourceProductlistexport(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getAppPrivatePublisherSourceProductlistexport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsDetailsWithLinksBySource(source: string, productCampaign: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCampaignDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsDetailsWithLinksBySource(source, productCampaign, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getProductsDetailsWithLinksBySource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<string>} [marketplaces] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [bids] 
         * @param {boolean} [favorite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsListWithLinksBySource(source: string, page?: number, limit?: number, stores?: Array<string>, marketplaces?: Array<string>, categories?: Array<number>, bids?: Array<string>, favorite?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsListWithLinksBySource200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsListWithLinksBySource(source, page, limit, stores, marketplaces, categories, bids, favorite, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getProductsListWithLinksBySource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceContentTypesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceContentTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceContentTypesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getSourceContentTypesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourceTypesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourceTypesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getSourceTypesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSourcesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSourcesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.getSourcesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {SourceDto2} sourceDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppPrivatePublisherSourceUpdate(source: string, sourceDto2: SourceDto2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppPrivatePublisherSourceUpdate(source, sourceDto2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.putAppPrivatePublisherSourceUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewASource(source: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SourceDto3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewASource(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherSourcesApi.viewASource']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublisherSourcesApi - factory interface
 * @export
 */
export const PublisherSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherSourcesApiFp(configuration)
    return {
        /**
         * 
         * @param {SourceDto} sourceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addASource(sourceDto: SourceDto, options?: RawAxiosRequestConfig): AxiosPromise<SourceDto3> {
            return localVarFp.addASource(sourceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppPrivatePublisherSourceDelete(source: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteAppPrivatePublisherSourceDelete(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivatePublisherSourceProductlistexport(source: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getAppPrivatePublisherSourceProductlistexport(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {string} productCampaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsDetailsWithLinksBySource(source: string, productCampaign: string, options?: RawAxiosRequestConfig): AxiosPromise<ProductCampaignDto> {
            return localVarFp.getProductsDetailsWithLinksBySource(source, productCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {Array<string>} [stores] 
         * @param {Array<string>} [marketplaces] 
         * @param {Array<number>} [categories] 
         * @param {Array<string>} [bids] 
         * @param {boolean} [favorite] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsListWithLinksBySource(source: string, page?: number, limit?: number, stores?: Array<string>, marketplaces?: Array<string>, categories?: Array<number>, bids?: Array<string>, favorite?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsListWithLinksBySource200Response> {
            return localVarFp.getProductsListWithLinksBySource(source, page, limit, stores, marketplaces, categories, bids, favorite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceContentTypesList(options?: RawAxiosRequestConfig): AxiosPromise<SourceContentTypeList> {
            return localVarFp.getSourceContentTypesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourceTypesList(options?: RawAxiosRequestConfig): AxiosPromise<SourceTypeList> {
            return localVarFp.getSourceTypesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSourcesList(options?: RawAxiosRequestConfig): AxiosPromise<SourceDtoList> {
            return localVarFp.getSourcesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {SourceDto2} sourceDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivatePublisherSourceUpdate(source: string, sourceDto2: SourceDto2, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAppPrivatePublisherSourceUpdate(source, sourceDto2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} source 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewASource(source: string, options?: RawAxiosRequestConfig): AxiosPromise<SourceDto3> {
            return localVarFp.viewASource(source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublisherSourcesApi - object-oriented interface
 * @export
 * @class PublisherSourcesApi
 * @extends {BaseAPI}
 */
export class PublisherSourcesApi extends BaseAPI {
    /**
     * 
     * @param {SourceDto} sourceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public addASource(sourceDto: SourceDto, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).addASource(sourceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public deleteAppPrivatePublisherSourceDelete(source: string, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).deleteAppPrivatePublisherSourceDelete(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getAppPrivatePublisherSourceProductlistexport(source: string, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getAppPrivatePublisherSourceProductlistexport(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {string} productCampaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getProductsDetailsWithLinksBySource(source: string, productCampaign: string, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getProductsDetailsWithLinksBySource(source, productCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {Array<string>} [stores] 
     * @param {Array<string>} [marketplaces] 
     * @param {Array<number>} [categories] 
     * @param {Array<string>} [bids] 
     * @param {boolean} [favorite] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getProductsListWithLinksBySource(source: string, page?: number, limit?: number, stores?: Array<string>, marketplaces?: Array<string>, categories?: Array<number>, bids?: Array<string>, favorite?: boolean, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getProductsListWithLinksBySource(source, page, limit, stores, marketplaces, categories, bids, favorite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getSourceContentTypesList(options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getSourceContentTypesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getSourceTypesList(options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getSourceTypesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public getSourcesList(options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).getSourcesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {SourceDto2} sourceDto2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public putAppPrivatePublisherSourceUpdate(source: string, sourceDto2: SourceDto2, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).putAppPrivatePublisherSourceUpdate(source, sourceDto2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} source 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherSourcesApi
     */
    public viewASource(source: string, options?: RawAxiosRequestConfig) {
        return PublisherSourcesApiFp(this.configuration).viewASource(source, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PublisherStatisticsApi - axios parameter creator
 * @export
 */
export const PublisherStatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publisher/v1/statistics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherStatisticsApi - functional programming interface
 * @export
 */
export const PublisherStatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherStatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistics(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistics(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublisherStatisticsApi.getStatistics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublisherStatisticsApi - factory interface
 * @export
 */
export const PublisherStatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherStatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getStatistics(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublisherStatisticsApi - object-oriented interface
 * @export
 * @class PublisherStatisticsApi
 * @extends {BaseAPI}
 */
export class PublisherStatisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherStatisticsApi
     */
    public getStatistics(options?: RawAxiosRequestConfig) {
        return PublisherStatisticsApiFp(this.configuration).getStatistics(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellerApi - axios parameter creator
 * @export
 */
export const SellerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToOzon: async (store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('connectMarketplaceToOzon', 'store', store)
            // verify required parameter 'ozonIntegrationDto' is not null or undefined
            assertParamExists('connectMarketplaceToOzon', 'ozonIntegrationDto', ozonIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (createCampaignDraft !== undefined) {
                localVarQueryParameter['create_campaign_draft'] = createCampaignDraft;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ozonIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createANewStore: async (storeDto: StoreDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeDto' is not null or undefined
            assertParamExists('createANewStore', 'storeDto', storeDto)
            const localVarPath = `/api/seller/v1/store/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivateSellerIntegrationOzonView: async (store: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('getAppPrivateSellerIntegrationOzonView', 'store', store)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/store/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationOzonUpdate: async (store: string, ozonIntegrationDto: OzonIntegrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationOzonUpdate', 'store', store)
            // verify required parameter 'ozonIntegrationDto' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationOzonUpdate', 'ozonIntegrationDto', ozonIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ozonIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {StoreDto2} storeDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerStoreUpdate: async (store: string, storeDto2: StoreDto2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('putAppPrivateSellerStoreUpdate', 'store', store)
            // verify required parameter 'storeDto2' is not null or undefined
            assertParamExists('putAppPrivateSellerStoreUpdate', 'storeDto2', storeDto2)
            const localVarPath = `/api/seller/v1/store/{store}/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeDto2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTheStore: async (store: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('removeTheStore', 'store', store)
            const localVarPath = `/api/seller/v1/store/{store}/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewTheStore: async (store: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('viewTheStore', 'store', store)
            const localVarPath = `/api/seller/v1/store/{store}/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerApi - functional programming interface
 * @export
 */
export const SellerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.connectMarketplaceToOzon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createANewStore(storeDto: StoreDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDto3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createANewStore(storeDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.createANewStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPrivateSellerIntegrationOzonView(store, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.getAppPrivateSellerIntegrationOzonView']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoresList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoresList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.getStoresList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.putAppPrivateSellerIntegrationOzonUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {StoreDto2} storeDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppPrivateSellerStoreUpdate(store: string, storeDto2: StoreDto2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppPrivateSellerStoreUpdate(store, storeDto2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.putAppPrivateSellerStoreUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTheStore(store: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTheStore(store, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.removeTheStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewTheStore(store: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreDto3>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewTheStore(store, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerApi.viewTheStore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellerApi - factory interface
 * @export
 */
export const SellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<MarketplaceStore> {
            return localVarFp.connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StoreDto} storeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createANewStore(storeDto: StoreDto, options?: RawAxiosRequestConfig): AxiosPromise<StoreDto3> {
            return localVarFp.createANewStore(storeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getAppPrivateSellerIntegrationOzonView(store, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresList(options?: RawAxiosRequestConfig): AxiosPromise<StoreDtoList> {
            return localVarFp.getStoresList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {StoreDto2} storeDto2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerStoreUpdate(store: string, storeDto2: StoreDto2, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAppPrivateSellerStoreUpdate(store, storeDto2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTheStore(store: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeTheStore(store, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewTheStore(store: string, options?: RawAxiosRequestConfig): AxiosPromise<StoreDto3> {
            return localVarFp.viewTheStore(store, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerApi - object-oriented interface
 * @export
 * @class SellerApi
 * @extends {BaseAPI}
 */
export class SellerApi extends BaseAPI {
    /**
     * 
     * @param {string} store 
     * @param {OzonIntegrationDto} ozonIntegrationDto 
     * @param {boolean} [createCampaignDraft] Create a campaign draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StoreDto} storeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public createANewStore(storeDto: StoreDto, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).createANewStore(storeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).getAppPrivateSellerIntegrationOzonView(store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public getStoresList(options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).getStoresList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {OzonIntegrationDto} ozonIntegrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {StoreDto2} storeDto2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public putAppPrivateSellerStoreUpdate(store: string, storeDto2: StoreDto2, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).putAppPrivateSellerStoreUpdate(store, storeDto2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public removeTheStore(store: string, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).removeTheStore(store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerApi
     */
    public viewTheStore(store: string, options?: RawAxiosRequestConfig) {
        return SellerApiFp(this.configuration).viewTheStore(store, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellerCampaignsApi - axios parameter creator
 * @export
 */
export const SellerCampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [paymentTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsList: async (paymentTypes?: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/campaign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentTypes) {
                localVarQueryParameter['payment_types[]'] = paymentTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsListForCampaign: async (slug: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getProductsListForCampaign', 'slug', slug)
            const localVarPath = `/api/seller/v1/campaign/{slug}/product/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignActivate: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignActivate', 'slug', slug)
            const localVarPath = `/api/seller/v1/campaign/{slug}/activate/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {ChangeCommissionDto} changeCommissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignChangecommission: async (slug: string, changeCommissionDto: ChangeCommissionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignChangecommission', 'slug', slug)
            // verify required parameter 'changeCommissionDto' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignChangecommission', 'changeCommissionDto', changeCommissionDto)
            const localVarPath = `/api/seller/v1/campaign/{slug}/product/change-commission/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCommissionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignDisable: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignDisable', 'slug', slug)
            const localVarPath = `/api/seller/v1/campaign/{slug}/disable/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {SetVisibilityDto} setVisibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignSetvisibility: async (slug: string, setVisibilityDto: SetVisibilityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignSetvisibility', 'slug', slug)
            // verify required parameter 'setVisibilityDto' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignSetvisibility', 'setVisibilityDto', setVisibilityDto)
            const localVarPath = `/api/seller/v1/campaign/{slug}/product/set-visibility/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setVisibilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {CampaignDto} campaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignUpdate: async (slug: string, campaignDto: CampaignDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignUpdate', 'slug', slug)
            // verify required parameter 'campaignDto' is not null or undefined
            assertParamExists('patchAppPrivateSellerCampaignUpdate', 'campaignDto', campaignDto)
            const localVarPath = `/api/seller/v1/campaign/{slug}/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewTheCampaign: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('viewTheCampaign', 'slug', slug)
            const localVarPath = `/api/seller/v1/campaign/{slug}/`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerCampaignsApi - functional programming interface
 * @export
 */
export const SellerCampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerCampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [paymentTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignsList(paymentTypes?: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignsList(paymentTypes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.getCampaignsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsListForCampaign(slug: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsListForCampaign200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsListForCampaign(slug, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.getProductsListForCampaign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppPrivateSellerCampaignActivate(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppPrivateSellerCampaignActivate(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.patchAppPrivateSellerCampaignActivate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {ChangeCommissionDto} changeCommissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppPrivateSellerCampaignChangecommission(slug: string, changeCommissionDto: ChangeCommissionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppPrivateSellerCampaignChangecommission(slug, changeCommissionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.patchAppPrivateSellerCampaignChangecommission']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppPrivateSellerCampaignDisable(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppPrivateSellerCampaignDisable(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.patchAppPrivateSellerCampaignDisable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {SetVisibilityDto} setVisibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppPrivateSellerCampaignSetvisibility(slug: string, setVisibilityDto: SetVisibilityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppPrivateSellerCampaignSetvisibility(slug, setVisibilityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.patchAppPrivateSellerCampaignSetvisibility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {CampaignDto} campaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAppPrivateSellerCampaignUpdate(slug: string, campaignDto: CampaignDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAppPrivateSellerCampaignUpdate(slug, campaignDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.patchAppPrivateSellerCampaignUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewTheCampaign(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewTheCampaign(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerCampaignsApi.viewTheCampaign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellerCampaignsApi - factory interface
 * @export
 */
export const SellerCampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerCampaignsApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [paymentTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignsList(paymentTypes?: Array<number>, options?: RawAxiosRequestConfig): AxiosPromise<CampaignDtoList> {
            return localVarFp.getCampaignsList(paymentTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {number} [page] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsListForCampaign(slug: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<GetProductsListForCampaign200Response> {
            return localVarFp.getProductsListForCampaign(slug, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignActivate(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchAppPrivateSellerCampaignActivate(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {ChangeCommissionDto} changeCommissionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignChangecommission(slug: string, changeCommissionDto: ChangeCommissionDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchAppPrivateSellerCampaignChangecommission(slug, changeCommissionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignDisable(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchAppPrivateSellerCampaignDisable(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {SetVisibilityDto} setVisibilityDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignSetvisibility(slug: string, setVisibilityDto: SetVisibilityDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchAppPrivateSellerCampaignSetvisibility(slug, setVisibilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {CampaignDto} campaignDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAppPrivateSellerCampaignUpdate(slug: string, campaignDto: CampaignDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchAppPrivateSellerCampaignUpdate(slug, campaignDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewTheCampaign(slug: string, options?: RawAxiosRequestConfig): AxiosPromise<CampaignDto2> {
            return localVarFp.viewTheCampaign(slug, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerCampaignsApi - object-oriented interface
 * @export
 * @class SellerCampaignsApi
 * @extends {BaseAPI}
 */
export class SellerCampaignsApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [paymentTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public getCampaignsList(paymentTypes?: Array<number>, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).getCampaignsList(paymentTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {number} [page] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public getProductsListForCampaign(slug: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).getProductsListForCampaign(slug, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public patchAppPrivateSellerCampaignActivate(slug: string, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).patchAppPrivateSellerCampaignActivate(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {ChangeCommissionDto} changeCommissionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public patchAppPrivateSellerCampaignChangecommission(slug: string, changeCommissionDto: ChangeCommissionDto, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).patchAppPrivateSellerCampaignChangecommission(slug, changeCommissionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public patchAppPrivateSellerCampaignDisable(slug: string, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).patchAppPrivateSellerCampaignDisable(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {SetVisibilityDto} setVisibilityDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public patchAppPrivateSellerCampaignSetvisibility(slug: string, setVisibilityDto: SetVisibilityDto, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).patchAppPrivateSellerCampaignSetvisibility(slug, setVisibilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {CampaignDto} campaignDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public patchAppPrivateSellerCampaignUpdate(slug: string, campaignDto: CampaignDto, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).patchAppPrivateSellerCampaignUpdate(slug, campaignDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerCampaignsApi
     */
    public viewTheCampaign(slug: string, options?: RawAxiosRequestConfig) {
        return SellerCampaignsApiFp(this.configuration).viewTheCampaign(slug, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellerLegalInfoApi - axios parameter creator
 * @export
 */
export const SellerLegalInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSellerLegalInfo: async (legalInfoDto: LegalInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalInfoDto' is not null or undefined
            assertParamExists('addSellerLegalInfo', 'legalInfoDto', legalInfoDto)
            const localVarPath = `/api/seller/v1/legal-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSellerLegalInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/legal-info/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerLegalInfoApi - functional programming interface
 * @export
 */
export const SellerLegalInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerLegalInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSellerLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSellerLegalInfo(legalInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerLegalInfoApi.addSellerLegalInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewSellerLegalInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.viewSellerLegalInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerLegalInfoApi.viewSellerLegalInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellerLegalInfoApi - factory interface
 * @export
 */
export const SellerLegalInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerLegalInfoApiFp(configuration)
    return {
        /**
         * 
         * @param {LegalInfoDto} legalInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSellerLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig): AxiosPromise<LegalInfoDto2> {
            return localVarFp.addSellerLegalInfo(legalInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewSellerLegalInfo(options?: RawAxiosRequestConfig): AxiosPromise<LegalInfoDto2> {
            return localVarFp.viewSellerLegalInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerLegalInfoApi - object-oriented interface
 * @export
 * @class SellerLegalInfoApi
 * @extends {BaseAPI}
 */
export class SellerLegalInfoApi extends BaseAPI {
    /**
     * 
     * @param {LegalInfoDto} legalInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerLegalInfoApi
     */
    public addSellerLegalInfo(legalInfoDto: LegalInfoDto, options?: RawAxiosRequestConfig) {
        return SellerLegalInfoApiFp(this.configuration).addSellerLegalInfo(legalInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerLegalInfoApi
     */
    public viewSellerLegalInfo(options?: RawAxiosRequestConfig) {
        return SellerLegalInfoApiFp(this.configuration).viewSellerLegalInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellerPaymentTypesApi - axios parameter creator
 * @export
 */
export const SellerPaymentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentTypesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/payment-types/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerPaymentTypesApi - functional programming interface
 * @export
 */
export const SellerPaymentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerPaymentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentTypesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentTypeList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentTypesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellerPaymentTypesApi.getPaymentTypesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellerPaymentTypesApi - factory interface
 * @export
 */
export const SellerPaymentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerPaymentTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentTypesList(options?: RawAxiosRequestConfig): AxiosPromise<PaymentTypeList> {
            return localVarFp.getPaymentTypesList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerPaymentTypesApi - object-oriented interface
 * @export
 * @class SellerPaymentTypesApi
 * @extends {BaseAPI}
 */
export class SellerPaymentTypesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerPaymentTypesApi
     */
    public getPaymentTypesList(options?: RawAxiosRequestConfig) {
        return SellerPaymentTypesApiFp(this.configuration).getPaymentTypesList(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellersIntegrationsApi - axios parameter creator
 * @export
 */
export const SellersIntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToOzon: async (store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('connectMarketplaceToOzon', 'store', store)
            // verify required parameter 'ozonIntegrationDto' is not null or undefined
            assertParamExists('connectMarketplaceToOzon', 'ozonIntegrationDto', ozonIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (createCampaignDraft !== undefined) {
                localVarQueryParameter['create_campaign_draft'] = createCampaignDraft;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ozonIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToWildberries: async (store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, createCampaignDraft?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('connectMarketplaceToWildberries', 'store', store)
            // verify required parameter 'wildberriesIntegrationDto' is not null or undefined
            assertParamExists('connectMarketplaceToWildberries', 'wildberriesIntegrationDto', wildberriesIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/wildberries/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (createCampaignDraft !== undefined) {
                localVarQueryParameter['create_campaign_draft'] = createCampaignDraft;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wildberriesIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAListOfAvailableMarketplaces: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/marketplace/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAListOfExistingMarketplaceIntegrations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/integration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivateSellerIntegrationOzonView: async (store: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('getAppPrivateSellerIntegrationOzonView', 'store', store)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExistMarketplaceIntegrationWithWildberries: async (store: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('getExistMarketplaceIntegrationWithWildberries', 'store', store)
            const localVarPath = `/api/seller/v1/store/{store}/integration/wildberries/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationOzonUpdate: async (store: string, ozonIntegrationDto: OzonIntegrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationOzonUpdate', 'store', store)
            // verify required parameter 'ozonIntegrationDto' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationOzonUpdate', 'ozonIntegrationDto', ozonIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/ozon/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ozonIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationWildberriesUpdate: async (store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'store' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationWildberriesUpdate', 'store', store)
            // verify required parameter 'wildberriesIntegrationDto' is not null or undefined
            assertParamExists('putAppPrivateSellerIntegrationWildberriesUpdate', 'wildberriesIntegrationDto', wildberriesIntegrationDto)
            const localVarPath = `/api/seller/v1/store/{store}/integration/wildberries/`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wildberriesIntegrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductsInAllIntegrations: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/marketplace-store/update-products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellersIntegrationsApi - functional programming interface
 * @export
 */
export const SellersIntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellersIntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.connectMarketplaceToOzon']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectMarketplaceToWildberries(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectMarketplaceToWildberries(store, wildberriesIntegrationDto, createCampaignDraft, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.connectMarketplaceToWildberries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAListOfAvailableMarketplaces(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAListOfAvailableMarketplaces(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.getAListOfAvailableMarketplaces']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAListOfExistingMarketplaceIntegrations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceIntegrationListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAListOfExistingMarketplaceIntegrations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.getAListOfExistingMarketplaceIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppPrivateSellerIntegrationOzonView(store, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.getAppPrivateSellerIntegrationOzonView']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExistMarketplaceIntegrationWithWildberries(store: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WildberriesIntegrationDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExistMarketplaceIntegrationWithWildberries(store, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.getExistMarketplaceIntegrationWithWildberries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.putAppPrivateSellerIntegrationOzonUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAppPrivateSellerIntegrationWildberriesUpdate(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAppPrivateSellerIntegrationWildberriesUpdate(store, wildberriesIntegrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.putAppPrivateSellerIntegrationWildberriesUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductsInAllIntegrations(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductsInAllIntegrations(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersIntegrationsApi.updateProductsInAllIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellersIntegrationsApi - factory interface
 * @export
 */
export const SellersIntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellersIntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<MarketplaceStore> {
            return localVarFp.connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {boolean} [createCampaignDraft] Create a campaign draft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectMarketplaceToWildberries(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<MarketplaceStore> {
            return localVarFp.connectMarketplaceToWildberries(store, wildberriesIntegrationDto, createCampaignDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAListOfAvailableMarketplaces(options?: RawAxiosRequestConfig): AxiosPromise<MarketplaceListDto> {
            return localVarFp.getAListOfAvailableMarketplaces(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAListOfExistingMarketplaceIntegrations(options?: RawAxiosRequestConfig): AxiosPromise<MarketplaceIntegrationListDto> {
            return localVarFp.getAListOfExistingMarketplaceIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getAppPrivateSellerIntegrationOzonView(store, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExistMarketplaceIntegrationWithWildberries(store: string, options?: RawAxiosRequestConfig): AxiosPromise<WildberriesIntegrationDto2> {
            return localVarFp.getExistMarketplaceIntegrationWithWildberries(store, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {OzonIntegrationDto} ozonIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} store 
         * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppPrivateSellerIntegrationWildberriesUpdate(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.putAppPrivateSellerIntegrationWildberriesUpdate(store, wildberriesIntegrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductsInAllIntegrations(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateProductsInAllIntegrations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellersIntegrationsApi - object-oriented interface
 * @export
 * @class SellersIntegrationsApi
 * @extends {BaseAPI}
 */
export class SellersIntegrationsApi extends BaseAPI {
    /**
     * 
     * @param {string} store 
     * @param {OzonIntegrationDto} ozonIntegrationDto 
     * @param {boolean} [createCampaignDraft] Create a campaign draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public connectMarketplaceToOzon(store: string, ozonIntegrationDto: OzonIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).connectMarketplaceToOzon(store, ozonIntegrationDto, createCampaignDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
     * @param {boolean} [createCampaignDraft] Create a campaign draft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public connectMarketplaceToWildberries(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, createCampaignDraft?: boolean, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).connectMarketplaceToWildberries(store, wildberriesIntegrationDto, createCampaignDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public getAListOfAvailableMarketplaces(options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).getAListOfAvailableMarketplaces(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public getAListOfExistingMarketplaceIntegrations(options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).getAListOfExistingMarketplaceIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public getAppPrivateSellerIntegrationOzonView(store: string, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).getAppPrivateSellerIntegrationOzonView(store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public getExistMarketplaceIntegrationWithWildberries(store: string, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).getExistMarketplaceIntegrationWithWildberries(store, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {OzonIntegrationDto} ozonIntegrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public putAppPrivateSellerIntegrationOzonUpdate(store: string, ozonIntegrationDto: OzonIntegrationDto, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).putAppPrivateSellerIntegrationOzonUpdate(store, ozonIntegrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} store 
     * @param {WildberriesIntegrationDto} wildberriesIntegrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public putAppPrivateSellerIntegrationWildberriesUpdate(store: string, wildberriesIntegrationDto: WildberriesIntegrationDto, options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).putAppPrivateSellerIntegrationWildberriesUpdate(store, wildberriesIntegrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersIntegrationsApi
     */
    public updateProductsInAllIntegrations(options?: RawAxiosRequestConfig) {
        return SellersIntegrationsApiFp(this.configuration).updateProductsInAllIntegrations(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SellersOnboardingApi - axios parameter creator
 * @export
 */
export const SellersOnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellersOnboardingForm: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/onboarding/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SellersInfoDto} sellersInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSellerOnboardingForm: async (sellersInfoDto: SellersInfoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellersInfoDto' is not null or undefined
            assertParamExists('sendSellerOnboardingForm', 'sellersInfoDto', sellersInfoDto)
            const localVarPath = `/api/seller/v1/onboarding/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellersInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellersOnboardingApi - functional programming interface
 * @export
 */
export const SellersOnboardingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellersOnboardingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellersOnboardingForm(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellersInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellersOnboardingForm(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersOnboardingApi.getSellersOnboardingForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SellersInfoDto} sellersInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSellerOnboardingForm(sellersInfoDto: SellersInfoDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellersInfoDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSellerOnboardingForm(sellersInfoDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SellersOnboardingApi.sendSellerOnboardingForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SellersOnboardingApi - factory interface
 * @export
 */
export const SellersOnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellersOnboardingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellersOnboardingForm(options?: RawAxiosRequestConfig): AxiosPromise<SellersInfoDto2> {
            return localVarFp.getSellersOnboardingForm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SellersInfoDto} sellersInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSellerOnboardingForm(sellersInfoDto: SellersInfoDto, options?: RawAxiosRequestConfig): AxiosPromise<SellersInfoDto2> {
            return localVarFp.sendSellerOnboardingForm(sellersInfoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellersOnboardingApi - object-oriented interface
 * @export
 * @class SellersOnboardingApi
 * @extends {BaseAPI}
 */
export class SellersOnboardingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersOnboardingApi
     */
    public getSellersOnboardingForm(options?: RawAxiosRequestConfig) {
        return SellersOnboardingApiFp(this.configuration).getSellersOnboardingForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SellersInfoDto} sellersInfoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersOnboardingApi
     */
    public sendSellerOnboardingForm(sellersInfoDto: SellersInfoDto, options?: RawAxiosRequestConfig) {
        return SellersOnboardingApiFp(this.configuration).sendSellerOnboardingForm(sellersInfoDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SignInSignUpApi - axios parameter creator
 * @export
 */
export const SignInSignUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LoginRequestDto} loginRequestDto User Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiLogin: async (loginRequestDto: LoginRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequestDto' is not null or undefined
            assertParamExists('postAppApiLogin', 'loginRequestDto', loginRequestDto)
            const localVarPath = `/api/private/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRegistrationDto} userRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiRegister: async (userRegistrationDto: UserRegistrationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegistrationDto' is not null or undefined
            assertParamExists('postAppApiRegister', 'userRegistrationDto', userRegistrationDto)
            const localVarPath = `/api/private/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignInSignUpApi - functional programming interface
 * @export
 */
export const SignInSignUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignInSignUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LoginRequestDto} loginRequestDto User Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppApiLogin(loginRequestDto: LoginRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppApiLogin(loginRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignInSignUpApi.postAppApiLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserRegistrationDto} userRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppApiRegister(userRegistrationDto: UserRegistrationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppApiRegister(userRegistrationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignInSignUpApi.postAppApiRegister']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SignInSignUpApi - factory interface
 * @export
 */
export const SignInSignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignInSignUpApiFp(configuration)
    return {
        /**
         * 
         * @param {LoginRequestDto} loginRequestDto User Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiLogin(loginRequestDto: LoginRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.postAppApiLogin(loginRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRegistrationDto} userRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiRegister(userRegistrationDto: UserRegistrationDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postAppApiRegister(userRegistrationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignInSignUpApi - object-oriented interface
 * @export
 * @class SignInSignUpApi
 * @extends {BaseAPI}
 */
export class SignInSignUpApi extends BaseAPI {
    /**
     * 
     * @param {LoginRequestDto} loginRequestDto User Credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignInSignUpApi
     */
    public postAppApiLogin(loginRequestDto: LoginRequestDto, options?: RawAxiosRequestConfig) {
        return SignInSignUpApiFp(this.configuration).postAppApiLogin(loginRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRegistrationDto} userRegistrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignInSignUpApi
     */
    public postAppApiRegister(userRegistrationDto: UserRegistrationDto, options?: RawAxiosRequestConfig) {
        return SignInSignUpApiFp(this.configuration).postAppApiRegister(userRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ThreadsApi - axios parameter creator
 * @export
 */
export const ThreadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ThreadDto} threadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread: async (threadDto: ThreadDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadDto' is not null or undefined
            assertParamExists('createThread', 'threadDto', threadDto)
            const localVarPath = `/api/seller/v1/messenger/threads/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threadDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfThreads: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/seller/v1/messenger/threads/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markThreadAsRead: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('markThreadAsRead', 'threadId', threadId)
            const localVarPath = `/api/seller/v1/messenger/threads/{threadId}/mark-read/`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThreadsApi - functional programming interface
 * @export
 */
export const ThreadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThreadsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ThreadDto} threadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThread(threadDto: ThreadDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThread(threadDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThreadsApi.createThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectionOfThreads(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadDtoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectionOfThreads(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThreadsApi.getCollectionOfThreads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markThreadAsRead(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadDto2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markThreadAsRead(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ThreadsApi.markThreadAsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ThreadsApi - factory interface
 * @export
 */
export const ThreadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThreadsApiFp(configuration)
    return {
        /**
         * 
         * @param {ThreadDto} threadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(threadDto: ThreadDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadDto2> {
            return localVarFp.createThread(threadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectionOfThreads(options?: RawAxiosRequestConfig): AxiosPromise<ThreadDtoList> {
            return localVarFp.getCollectionOfThreads(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markThreadAsRead(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<ThreadDto2> {
            return localVarFp.markThreadAsRead(threadId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThreadsApi - object-oriented interface
 * @export
 * @class ThreadsApi
 * @extends {BaseAPI}
 */
export class ThreadsApi extends BaseAPI {
    /**
     * 
     * @param {ThreadDto} threadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreadsApi
     */
    public createThread(threadDto: ThreadDto, options?: RawAxiosRequestConfig) {
        return ThreadsApiFp(this.configuration).createThread(threadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreadsApi
     */
    public getCollectionOfThreads(options?: RawAxiosRequestConfig) {
        return ThreadsApiFp(this.configuration).getCollectionOfThreads(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreadsApi
     */
    public markThreadAsRead(threadId: string, options?: RawAxiosRequestConfig) {
        return ThreadsApiFp(this.configuration).markThreadAsRead(threadId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheUserInformation: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRolesDto} userRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiUserSetRoles: async (userRolesDto: UserRolesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRolesDto' is not null or undefined
            assertParamExists('postAppApiUserSetRoles', 'userRolesDto', userRolesDto)
            const localVarPath = `/api/private/user/roles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRolesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTheUserInformation(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTheUserInformation(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getTheUserInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserRolesDto} userRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppApiUserSetRoles(userRolesDto: UserRolesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppApiUserSetRoles(userRolesDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.postAppApiUserSetRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTheUserInformation(options?: RawAxiosRequestConfig): AxiosPromise<UserDto> {
            return localVarFp.getTheUserInformation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRolesDto} userRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppApiUserSetRoles(userRolesDto: UserRolesDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postAppApiUserSetRoles(userRolesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getTheUserInformation(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getTheUserInformation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRolesDto} userRolesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postAppApiUserSetRoles(userRolesDto: UserRolesDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).postAppApiUserSetRoles(userRolesDto, options).then((request) => request(this.axios, this.basePath));
    }
}



