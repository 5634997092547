import { ReactNode } from 'react';

import type { FeeSetting2 } from '@Shared/api/rtkQueryApis/sellerCampaigns';

export type CampaignType = {
    title: string;
    description: string;
    disabled: boolean;
    icon: ReactNode;
    navigate?: string;
    hasButton?: boolean;
};

export enum ActionType {
    changeCommission = 'changeCommission',
    defaultCommission = 'defaultCommission',
    showStop = 'showStop',
    showStart = 'showStart',
}

export type ApplyActionProps = {
    producstsIds: string[];
    action: ActionType;
    commission: number;
};

export type CampaignContextType = {
    fee_setting?: FeeSetting2 | null;
    applyAction: ({ producstsIds, action, commission }: ApplyActionProps) => Promise<false | undefined>;
    totalItems?: number;
    currentPage?: number;
    setCurrentPage?: (page: number) => void;
};

export const actionOptions = [
    { value: ActionType.changeCommission, label: 'Изменить комиссию' },
    { value: ActionType.defaultCommission, label: 'Коммисия по умолчанию' },
    { value: ActionType.showStop, label: 'Остановить показ' },
    { value: ActionType.showStart, label: 'Возобновить показ' },
];

export const PAGE_SIZE = 50;
