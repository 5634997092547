import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';

import { Button, Col, Typography, Avatar, Row, Divider } from '@Shared/ui';
import { SourceDto3, ProductCampaignDto } from '@Shared/api/rtkQueryApis/publisherSources';
import { CopyCardValue } from '@Features/shared';

import './styles.scss';

const { Text, Title, Link } = Typography;

type ProductDetailsProps = {
    productCampaign: ProductCampaignDto;
    source: SourceDto3;
    isUserFilledTheLegalInfo: boolean;
};

export const ProductDetails: FC<ProductDetailsProps> = ({ productCampaign, source, isUserFilledTheLegalInfo }) => {
    const navigate = useNavigate();

    return (
        <>
            <Row
                gutter={[0, 32]}
                wrap={false}
            >
                <Col flex="auto">
                    <Row>
                        <Title
                            style={{ marginTop: 0, marginBottom: 8 }}
                            level={3}
                        >
                            {productCampaign?.product?.title}
                        </Title>
                    </Row>
                </Col>
                <Col
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    flex="80px"
                >
                    <Avatar
                        shape="square"
                        icon={<FileOutlined />}
                        style={{ float: 'left', marginRight: 'var(--ant-margin-sm)' }}
                    />
                </Col>
            </Row>
            <Title level={5}>Партнерская ссылка</Title>
            <CopyCardValue
                className={isUserFilledTheLegalInfo ? '' : 'blured-link-block'}
                cardValue={isUserFilledTheLegalInfo ? productCampaign?.tracking_link : 'https://fake.link/1A2B3C'}
                copyMessageText="Партнерская ссылка успешно скопирована"
            />
            {productCampaign?.product?.external_link ? (
                <>
                    <Title level={5}>Ссылка на продукт в МП</Title>
                    <Link
                        href={productCampaign?.product?.external_link || ''}
                        target="_blank"
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '100%',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {productCampaign.product?.external_link}
                    </Link>
                </>
            ) : null}
            <Title level={5}>Площадка</Title>
            <Row>
                <Divider style={{ margin: 0 }} />
                <Row
                    align="middle"
                    justify="space-between"
                    style={{ width: '100%' }}
                >
                    <Text>{source.title}</Text>
                    <Button
                        type="link"
                        onClick={() => navigate('/sources', { state: { initialSourceId: source.id } })}
                    >
                        Детали
                    </Button>
                </Row>
                <Divider style={{ margin: 0 }} />
            </Row>
        </>
    );
};
