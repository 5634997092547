import { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

export default function Theme({ children }: { children: ReactNode }) {
    return (
        <ConfigProvider
            locale={ru_RU}
            theme={{
                // hashed: false,
                cssVar: true,
                token: {
                    colorPrimary: '#FF6A2A',
                    colorPrimaryBg: '#FEFAEE',
                    colorLightSilver: '#D9D9D9',
                    'catunic.1': '#fff3e6',
                    'catunic.2': '#ffd1a3',
                    'catunic.3': '#ffb87a',
                    'catunic.4': '#ff9d52',
                    'catunic.5': '#ff7e29',
                    'catunic.6': '#ff5f00',
                    'catunic.7': '#d94800',
                    'catunic.8': '#b33600',
                    'catunic.9': '#8c2500',
                    'catunic.10': '#661800',
                    colorInfo: '#ff5f00',
                    controlOutline: 'rgba(255, 95, 0, 0.1)',
                    controlHeight: 40,
                    controlHeightLG: 44,
                    controlHeightSM: 36,
                    controlHeightXS: 32,
                    fontFamily: 'Rubik',
                    fontFamilyCode: 'Roboto Mono',
                    colorBgContainer: '#F5F5F5',
                    headerPadding: 0,
                    headerHeight: 60,
                    paddingSM: 16,
                },
                components: {
                    Button: {
                        colorPrimary: '#FF6A2A',
                        algorithm: true,
                        borderRadius: 10,
                        paddingInlineSM: 12,
                        paddingInlineLG: 16,
                        paddingInline: 16,
                    },
                    Radio: {
                        buttonBg: '#fff',
                    },
                    Card: {
                        colorBgContainer: '#fff',
                    },
                    DatePicker: {
                        colorBgContainer: '#fff',
                    },
                    Input: {
                        colorBgContainer: '#FFF',
                        colorPrimary: '#FF6A2A',
                        borderRadius: 10,
                        algorithm: true,
                    },
                    InputNumber: {
                        colorBgContainer: '#FFF',
                        colorPrimary: '#FF6A2A',
                        borderRadius: 10,
                        algorithm: true,
                    },
                    Select: {
                        colorPrimary: '#FF6A2A',
                        borderRadius: 10,
                        algorithm: true,
                    },
                    Menu: {
                        iconSize: 16,
                        itemPaddingInline: 8,
                        algorithm: true,
                    },
                    Table: {
                        bodySortBg: '#fff',
                        algorithm: true,
                    },
                    Drawer: {
                        zIndexPopup: 999,
                    },
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
}
