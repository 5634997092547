import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAListOfExistingMarketplaceIntegrations: build.query<
            GetAListOfExistingMarketplaceIntegrationsApiResponse,
            GetAListOfExistingMarketplaceIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/integration/' }),
        }),
        getAppPrivateSellerIntegrationOzonView: build.query<
            GetAppPrivateSellerIntegrationOzonViewApiResponse,
            GetAppPrivateSellerIntegrationOzonViewApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/` }),
        }),
        putAppPrivateSellerIntegrationOzonUpdate: build.mutation<
            PutAppPrivateSellerIntegrationOzonUpdateApiResponse,
            PutAppPrivateSellerIntegrationOzonUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'PUT',
                body: queryArg.ozonIntegrationDto,
            }),
        }),
        connectMarketplaceToOzon: build.mutation<ConnectMarketplaceToOzonApiResponse, ConnectMarketplaceToOzonApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'POST',
                body: queryArg.ozonIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        getExistMarketplaceIntegrationWithWildberries: build.query<
            GetExistMarketplaceIntegrationWithWildberriesApiResponse,
            GetExistMarketplaceIntegrationWithWildberriesApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/` }),
        }),
        putAppPrivateSellerIntegrationWildberriesUpdate: build.mutation<
            PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse,
            PutAppPrivateSellerIntegrationWildberriesUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'PUT',
                body: queryArg.wildberriesIntegrationDto,
            }),
        }),
        connectMarketplaceToWildberries: build.mutation<
            ConnectMarketplaceToWildberriesApiResponse,
            ConnectMarketplaceToWildberriesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'POST',
                body: queryArg.wildberriesIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        getAListOfAvailableMarketplaces: build.query<
            GetAListOfAvailableMarketplacesApiResponse,
            GetAListOfAvailableMarketplacesApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace/' }),
        }),
        updateProductsInAllIntegrations: build.mutation<
            UpdateProductsInAllIntegrationsApiResponse,
            UpdateProductsInAllIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace-store/update-products/', method: 'POST' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerIntegrationsApi };
export type GetAListOfExistingMarketplaceIntegrationsApiResponse =
    /** status 201 Resource created */ MarketplaceIntegrationListDto;
export type GetAListOfExistingMarketplaceIntegrationsApiArg = void;
export type GetAppPrivateSellerIntegrationOzonViewApiResponse = unknown;
export type GetAppPrivateSellerIntegrationOzonViewApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationOzonUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationOzonUpdateApiArg = {
    store: string;
    ozonIntegrationDto: OzonIntegrationDto;
};
export type ConnectMarketplaceToOzonApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToOzonApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    ozonIntegrationDto: OzonIntegrationDto;
};
export type GetExistMarketplaceIntegrationWithWildberriesApiResponse =
    /** status 200 Resource response */ WildberriesIntegrationDto2;
export type GetExistMarketplaceIntegrationWithWildberriesApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiArg = {
    store: string;
    wildberriesIntegrationDto: WildberriesIntegrationDto;
};
export type ConnectMarketplaceToWildberriesApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToWildberriesApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    wildberriesIntegrationDto: WildberriesIntegrationDto;
};
export type GetAListOfAvailableMarketplacesApiResponse = /** status 201 Resource created */ MarketplaceListDto;
export type GetAListOfAvailableMarketplacesApiArg = void;
export type UpdateProductsInAllIntegrationsApiResponse = unknown;
export type UpdateProductsInAllIntegrationsApiArg = void;
export type MarketplaceIntegrationDto = {
    id: string;
    store_id: string;
    marketplace_slug: MarketPlaceSlugEnum2;
};
export type MarketplaceIntegrationListDto = {
    items?: MarketplaceIntegrationDto[];
};
export type OzonIntegrationDto = {
    organization_id: string;
    seller_client_id: string;
    seller_api_key: string;
    performance_client_id: string;
    performance_api_key: string;
};
export type MarketplaceStore = {
    id: string;
};
export type WildberriesIntegrationDto2 = {
    organization_id: string;
    refresh_token: string;
    validation_key: string;
    seller_device_id: string;
    authorize_v3_token?: string | null;
    api_authorization_token: string | null;
};
export type WildberriesIntegrationDto = {
    organization_id: string;
    refresh_token: string;
    validation_key: string;
    seller_device_id: string;
    authorize_v3_token?: string | null;
    api_authorization_token: string | null;
};
export type MarketplaceDto = {
    slug: string;
    title: string;
    description?: string | null;
};
export type MarketplaceListDto = {
    items?: MarketplaceDto[];
};
export enum MarketPlaceSlugEnum2 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export const {
    useGetAListOfExistingMarketplaceIntegrationsQuery,
    useGetAppPrivateSellerIntegrationOzonViewQuery,
    usePutAppPrivateSellerIntegrationOzonUpdateMutation,
    useConnectMarketplaceToOzonMutation,
    useGetExistMarketplaceIntegrationWithWildberriesQuery,
    usePutAppPrivateSellerIntegrationWildberriesUpdateMutation,
    useConnectMarketplaceToWildberriesMutation,
    useGetAListOfAvailableMarketplacesQuery,
    useUpdateProductsInAllIntegrationsMutation,
} = injectedRtkApi;
