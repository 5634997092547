import { StarFilled } from '@ant-design/icons';

import { Button } from '@Shared/ui';
import {
    useAddAProductCampaignToFavoritesMutation,
    useRemoveProductCampaignFromFavoritesMutation,
} from '@Shared/api/rtkQueryApis/productCampaignFavorites';

const FavoriteCell = ({ isFavorite = false, productId }: { isFavorite: boolean; productId: string }) => {
    const [addAProductCampaignToFavorites] = useAddAProductCampaignToFavoritesMutation();
    const [removeProductCampaignFromFavorites] = useRemoveProductCampaignFromFavoritesMutation();

    const toggleProductFavorite = async (e) => {
        e.stopPropagation();
        if (isFavorite) {
            await removeProductCampaignFromFavorites({ productCampaign: productId });
        } else {
            await addAProductCampaignToFavorites({ productCampaign: productId });
        }
    };

    return (
        <Button
            type="link"
            onClick={toggleProductFavorite}
            icon={<StarFilled style={{ color: isFavorite ? '#FADB14' : '#F5F5F5', fontSize: 20 }} />}
        />
    );
};

export default FavoriteCell;
