import { ReactNode, useEffect, useState } from 'react';

import { ProductDto } from '@OpenApi/typescript-axios';
import { ProductsDrawer } from '@Features/seller/products/productsTable/ProductsDrawer';
import { Table, Typography } from '@Shared/ui';

const { Link, Text } = Typography;

type ProductsTableProps = { data: ProductDto[]; isLoading: boolean; EmptyTableView: ReactNode };

export const ProductsTable = ({ data, isLoading, EmptyTableView }: ProductsTableProps) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState('');

    const [convertedData, setConvertedData] = useState<
        (ProductDto & { nameCell: { externalId: string; title: string }; priceCell: string })[]
    >([]);

    const columns = [
        {
            title: 'Название товара',
            dataIndex: 'nameCell',
            key: 'nameCell',
            ellipsis: true,
            render: (nameCell: { externalId: string; title: string }) => (
                <>
                    <Text strong>{nameCell.title}</Text>
                    <br />
                    <Text type="secondary">Арт.: {nameCell.externalId}</Text>
                </>
            ),
        },
        {
            title: 'Баркод',
            dataIndex: 'sku',
            key: 'sku',
            render: (sku: string) => <>{sku}</>,
        },
        {
            title: 'Стоимость',
            dataIndex: 'priceCell',
            key: 'priceCell',
            render: (priceCell: string) => <>{priceCell}</>,
        },
        {
            title: 'Магазин',
            dataIndex: 'marketplace_store',
            key: 'store',
            ellipsis: true,
            render: (marketplace_store: { store: { title: unknown } }) => <>{marketplace_store?.store?.title}</>,
        },
        {
            title: 'Маркетплейс',
            dataIndex: 'marketplace_store',
            key: 'marketplace_store',
            ellipsis: true,
            render: (marketplace_store: { marketplace: { title: unknown } }) => (
                <>{marketplace_store?.marketplace?.title}</>
            ),
        },
        {
            title: 'Ссылка на МП',
            dataIndex: 'external_link',
            key: 'external_link',
            ellipsis: true,
            render: (external_link: string) => (
                <Link
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    target="_blank"
                    href={external_link}
                >
                    {external_link}
                </Link>
            ),
        },
    ];

    useEffect(() => {
        if (data) {
            setConvertedData(
                data.map((item: ProductDto) => ({
                    ...item,
                    nameCell: { title: item.title, externalId: item.external_id },
                    priceCell: `${item.price} ${item.currency?.symbol}`,
                })),
            );
        }
    }, [data]);

    return (
        <>
            <Table
                dataSource={convertedData}
                loading={isLoading}
                columns={columns}
                scroll={{ x: '1000px' }}
                pagination={false}
                className="products-table"
                locale={{
                    emptyText: EmptyTableView,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setCurrentProductId(record.id);
                            setDrawerOpen(true);
                        },
                    };
                }}
            ></Table>
            <ProductsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                productId={currentProductId}
            />
        </>
    );
};
