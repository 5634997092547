import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

import { seller_app_router } from '../routes';

const SellerApp: FC = () => {
    return (
        <RouterProvider
            router={seller_app_router}
            fallbackElement={<div>Page not found</div>}
        />
    );
};

export default SellerApp;
