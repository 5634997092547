import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InputNumber } from '@Shared/ui';
import { type FeeSetting2, type ProductCampaign } from '@Shared/api/rtkQueryApis/sellerCampaigns';

import { ActionType } from './types';

type ComissionCellProps = {
    product: ProductCampaign;
    campaign_fee_setting: FeeSetting2 | null | undefined;
    applyAction: (params: Record<string, unknown>) => void;
};

const getCommissionValue = (fee_setting, campaign_fee_setting) =>
    (fee_setting?.fee_value || campaign_fee_setting?.fee_value) * 100 || 10;

export const ComissionCell = ({
    product: { id, fee_setting },
    campaign_fee_setting,
    applyAction,
}: ComissionCellProps) => {
    const [value, setValue] = useState(getCommissionValue(fee_setting, campaign_fee_setting));

    const applyActionDebounced = useDebouncedCallback((value) => {
        applyAction({
            producstsIds: [id],
            action: ActionType.changeCommission,
            commission: value / 100,
        });
    }, 1000);

    useEffect(() => {
        setValue(getCommissionValue(fee_setting, campaign_fee_setting));
    }, [fee_setting, campaign_fee_setting]);

    const handleChange = (value: string | number | null) => {
        setValue(value);
        applyActionDebounced(value);
    };

    return (
        <InputNumber
            changeOnBlur
            value={value}
            min={fee_setting?.min_total_fee || campaign_fee_setting?.min_total_fee || 10}
            onChange={handleChange}
            addonAfter="%"
            step="1"
        />
    );
};
