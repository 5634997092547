import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { EmptyProductsTableView, ProductsFilter, ProductsTable, UpdateProductsBtn } from '@Features/seller/products';
import {
    selectState as selectMarketplacesState,
    MarketplacesKeyValueType,
    fetchData as fetchMarketplaces,
} from '@Entities/keyValue/seller/marketplaces/model/marketplacesSlice';
import { fetchData, ProductsKeyValueType, selectState } from '@Entities/keyValue/seller/products/model/productsSlice';
import {
    fetchData as fetchStores,
    selectState as selectStoresState,
    StoresKeyValueType,
} from '@Entities/keyValue/seller/stores/model/storesSlice';
import { PaginationSearchOrderEnum, ProductsFilterEnum } from '@Features/seller/products/model/types';
import { ProductsPagination } from '@Features/seller/products/Pagination';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Flex, Grid, Typography } from '@Shared/ui';

import './styles.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const ProductsTab = () => {
    const dispatch = useDispatch();
    const breakpoints = useBreakpoint();
    const { isLoading, value }: ProductsKeyValueType = useSelector(selectState);
    const { isLoading: isMarketplacesLoading, value: marketplaces }: MarketplacesKeyValueType =
        useSelector(selectMarketplacesState);
    const { isLoading: isStoresLoading, value: stores }: StoresKeyValueType = useSelector(selectStoresState);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!stores) {
            dispatch(
                fetchStores({
                    key: KeyValueKeysEnum.getStoresList,
                }),
            );
        }

        if (!marketplaces) {
            dispatch(
                fetchMarketplaces({
                    key: KeyValueKeysEnum.getMarketplacesList,
                }),
            );
        }
    }, [dispatch, stores, marketplaces]);

    useEffect(() => {
        dispatch(
            fetchData({
                key: KeyValueKeysEnum.getProductList,
                params: {
                    page: searchParams.get(PaginationSearchOrderEnum.PAGE),
                    limit: searchParams.get(PaginationSearchOrderEnum.LIMIT),
                    stores: searchParams.getAll(ProductsFilterEnum.STORES),
                    marketplaces: searchParams.getAll(ProductsFilterEnum.MARKETPLACES),
                },
            }),
        );
    }, [searchParams, dispatch]);

    return (
        <>
            <Flex
                justify="space-between"
                gap={10}
                vertical={!breakpoints.lg}
                style={{ marginBottom: 24 }}
            >
                <Flex
                    vertical
                    justify="space-between"
                >
                    <Text type="secondary">
                        В этом разделе представлен общий список товаров из ваших магазинов на определенном маркетплейсе.
                    </Text>
                </Flex>
                <UpdateProductsBtn />
            </Flex>
            <Text type="secondary">
                Автоматически список продуктов актуализируется для всех ваших магазинов раз в сутки в 00:00 (МСК). Если
                вам необходимо актуализировать список вручную, то вы можете это сделать по кнопке &quot;Обновить список
                продуктов&quot; раз в сутки
            </Text>

            <ProductsFilter
                stores={stores?.items}
                marketplaces={marketplaces?.items}
            />

            <ProductsTable
                data={value?.items || []}
                isLoading={isLoading || isMarketplacesLoading || isStoresLoading}
                EmptyTableView={<EmptyProductsTableView stores={stores?.items || []} />}
            />

            <ProductsPagination total={value?.items?.length} />
        </>
    );
};
export default ProductsTab;
