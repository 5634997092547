import { Layout, Menu, type MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { pageMenuItems, logoMenuItem } from '@Widgets/publisher/menuItems';

import './styles.scss';

const { Sider } = Layout;

const sidebarMenu = [logoMenuItem, ...pageMenuItems];

type PageMenuProps = {
    menuCollapsed: boolean;
    selectedKey: string;
};

export default function PageMenu({ menuCollapsed, selectedKey }: PageMenuProps) {
    const navigate = useNavigate();

    const onClick: MenuProps['onClick'] = (e) => {
        const item = sidebarMenu.find((item) => item.key === e.key);
        if (item && item.href) {
            navigate(item.href);
        }
    };

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={menuCollapsed}
            width={256}
        >
            <Menu
                selectedKeys={[selectedKey]}
                mode="vertical"
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                onClick={onClick}
                theme="dark"
                items={sidebarMenu}
                className="sidebar-menu"
            />
        </Sider>
    );
}
