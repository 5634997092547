import { createElement } from 'react';
import Icon, {
    // SettingOutlined,
    MoneyCollectOutlined,
    ShoppingOutlined,
    // DashboardOutlined,
    ProductOutlined,
    MessageOutlined,
} from '@ant-design/icons';

import { BookLegalLow, SingleUser, ExitLogOut } from '@Shared/assets/images/icons';
import { LogoEllipse } from '@Shared/assets/images';

const SITE_NAME = 'Takprodam';

export const logoMenuItem = {
    className: 'sidebar-logo',
    icon: <Icon component={() => <LogoEllipse style={{ width: '22px', height: '22px' }} />} />,
    label: SITE_NAME,
};

export const pageMenuItems = [
    // {
    //     key: 'dashboard',
    //     href: '/',
    //     icon: createElement(DashboardOutlined),
    //     label: 'Дашбоард',
    // },
    {
        key: 'stores',
        href: '/stores',
        icon: createElement(ShoppingOutlined),
        label: 'Магазины',
    },
    {
        key: 'products',
        href: '/product-promotion',
        icon: createElement(ProductOutlined),
        label: 'Продвижение товаров',
    },
    // {
    //     key: 'webmasters',
    //     href: '/webmasters',
    //     icon: createElement(UserOutlined),
    //     label: 'Вебмастеры',
    // },
    {
        key: 'payments',
        href: '/payments',
        icon: createElement(MoneyCollectOutlined),
        label: 'Сверка',
    },
    {
        key: 'messenger',
        href: '/messenger',
        icon: createElement(MessageOutlined),
        label: 'Мессенджер',
    },
    // {
    //     key: 'settings',
    //     href: '/settings',
    //     icon: createElement(SettingOutlined),
    //     label: 'Настройки',
    // },
];

export const userMenuItems = [
    {
        key: 'profile',
        label: 'Профиль',
        disabled: true,
        icon: <Icon component={() => <SingleUser />} />,
    },
    {
        key: 'legal-info',
        href: '/legal-info',
        label: 'Юридические данные',
        icon: <Icon component={() => <BookLegalLow style={{ opacity: '0.45' }} />} />,
    },
    {
        type: 'divider',
    },
    {
        key: 'logout',
        label: 'Выйти',
        disabled: true,
        icon: <Icon component={() => <ExitLogOut />} />,
    },
];
