import { createElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, theme } from 'antd';
import { UserOutlined, BellOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';

import { Flex, Avatar } from '@Shared/ui';
import { pageMenuItems, logoMenuItem, userMenuItems } from '@Widgets/seller/menuItems';

import './styles.scss';

const { Header } = Layout;

type MobilePageHeaderProps = {
    selectedKey: string;
};

export default function MobilePageHeader({ selectedKey: selectedPageMenuKey }: MobilePageHeaderProps) {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer, colorBgSpotlight },
    } = theme.useToken();
    const [openKeys, setOpenKeys] = useState([]);

    const isPageMenuOpened = openKeys.indexOf('page-menu') !== -1;

    const headerItems = [
        {
            key: 'page-menu',
            label: false,
            popupClassName: 'page-menu-popup',
            className: 'hide-title-content',
            theme: 'light',
            icon: isPageMenuOpened ? <CloseOutlined /> : <MenuOutlined />,
            children: pageMenuItems,
        },
        { ...logoMenuItem, className: 'logo-menu-item' },
        {
            key: 'notifications',
            label: false,
            className: 'hide-title-content',
            icon: createElement(BellOutlined),
        },
        {
            key: 'user',
            label: false,
            children: userMenuItems,
            popupClassName: 'page-menu-popup',
            className: 'hide-title-content',
            theme: 'light',
            icon: (
                <Avatar
                    style={{ background: colorBgContainer }}
                    icon={<UserOutlined style={{ color: colorBgSpotlight }} />}
                />
            ),
        },
    ];

    const onClick = (e) => {
        const item = [...pageMenuItems, ...userMenuItems].find((item) => item.key === e.key);
        if (item && item.href) {
            navigate(item.href);
        }
    };

    const handleMenuOpen = (keys) => {
        setOpenKeys(keys);
    };

    return (
        <Header className="page-header__mobile">
            <Flex
                justify="space-between"
                align="end"
                style={{ padding: '0 16px' }}
            >
                <Menu
                    selectedKeys={isPageMenuOpened ? [selectedPageMenuKey] : []}
                    mode="horizontal"
                    onClick={onClick}
                    onOpenChange={handleMenuOpen}
                    openKeys={openKeys}
                    items={headerItems}
                    theme="dark"
                    className="header-mobile-menu"
                />
            </Flex>
        </Header>
    );
}
