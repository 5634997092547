import { useNavigate } from 'react-router-dom';

import { SourceStatusEnum, SourceDto3 } from '@Shared/api/rtkQueryApis/publisherSources';
import { Badge, Button, Table, Typography } from '@Shared/ui';
import { getSourceStatusBadgeType } from '@Shared/utils/sources/getSourceStatusBadgeType';
import { renderEmpty } from '@Features/shared';

import ActionsMenu from './ActionsMenu';

const { Column } = Table;
const { Text } = Typography;

type SourcesTableProps = {
    data: SourceDto3[];
    isLoading: boolean;
    setDrawerOpen: (arg1: boolean) => void;
    setDeletionModalOpen: (arg1: boolean) => void;
    setCurrentSourceId: (arg1: string) => void;
};

const SourcesTable = ({
    data,
    isLoading,
    setDrawerOpen,
    setDeletionModalOpen,
    setCurrentSourceId,
}: SourcesTableProps) => {
    const navigate = useNavigate();

    return (
        <Table
            dataSource={data}
            loading={isLoading}
            style={{ marginTop: 24 }}
            scroll={{ x: '1000px' }}
            pagination={false}
            className="sources-table"
            rowKey={(record) => record?.id || ''}
            locale={{
                emptyText: renderEmpty({
                    title: 'Здесь пока пусто',
                    content: (
                        <>
                            <Text>Добавьте хотя бы одну площадку и верифицируйте её.</Text>
                            <Button
                                type="link"
                                onClick={() => navigate('/sources/add-source')}
                            >
                                Добавить площадку
                            </Button>
                        </>
                    ),
                }),
            }}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setCurrentSourceId(record?.id || '');
                        setDrawerOpen(true);
                    },
                };
            }}
        >
            <Column
                title="Название"
                dataIndex="title"
                key="title"
                ellipsis
                className="text-multiline"
                render={(title: string) => <span style={{ marginLeft: 16 }}>{title}</span>}
            />
            <Column
                title="Статус"
                dataIndex="status"
                key="status"
                render={(status: SourceStatusEnum) => (
                    <Badge
                        status={getSourceStatusBadgeType(status)}
                        text={status}
                    />
                )}
            />
            <Column
                title="Тип площадки"
                dataIndex="source_type"
                key="source_type"
                render={(source_type: { title: string }) => <>{source_type.title}</>}
            />
            <Column
                title="Ссылка"
                dataIndex="source_url"
                key="source_url"
                className="text-multiline"
                render={(source_url: string) => (
                    <Button
                        type="link"
                        href={source_url}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                    >
                        {source_url}
                    </Button>
                )}
            />
            <Column
                title="Комментарий"
                dataIndex="comment"
                key="comment"
            />
            <Column
                align="right"
                title=""
                key="actions"
                render={(_, record) => (
                    <ActionsMenu
                        currentSourceId={record.id}
                        setCurrentSourceId={setCurrentSourceId}
                        setDrawerOpen={setDrawerOpen}
                        setDeletionModalOpen={setDeletionModalOpen}
                        sourceStatus={record.status}
                    />
                )}
            />
        </Table>
    );
};

export default SourcesTable;
