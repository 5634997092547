import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Layout, theme } from 'antd';
import { useLocation } from 'react-router-dom';

import { SetupIntegrationForm, IntegrationFormSelectors, SetupIntegrationBreadcrumb } from '@Features/seller/stores';
import { fetchData } from '@Entities/keyValue/seller/stores/model/storesSlice';
import { KeyValueKeysEnum } from '@Shared/model/seller/types';
import { Button, Typography, Space, Card, Row, Col, Grid } from '@Shared/ui';

import './styles.scss';

const { Content } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const SetupIntegrationPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const breakpoints = useBreakpoint();
    const [selectedMarketplace, setSelectedMarketplace] = useState(location.state?.selectedMarketplace || null);
    const [selectedStoreId, setSelectedStoreId] = useState(location.state?.selectedStoreId || null);

    useEffect(() => {
        dispatch(
            fetchData({
                key: KeyValueKeysEnum.getStoresList,
            }),
        );
    }, [dispatch]);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Content
            className="page-content setup-integration-page"
            style={{
                background: colorBgContainer,
                padding: breakpoints.md ? 24 : '24px 0',
            }}
        >
            <SetupIntegrationBreadcrumb />

            <Row
                gutter={[10, 10]}
                wrap={false}
                style={{ flexWrap: breakpoints.lg ? 'nowrap' : 'wrap', rowGap: breakpoints.md ? 10 : 0 }}
            >
                <Col flex="auto">
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Title level={3}>Настройка интеграции</Title>
                        <Text type="secondary">
                            Здесь вы сможете настроить интеграцию с магазином. Подробнее об условиях маркетплейса вы
                            можете почитать в разделе помощи.
                        </Text>
                        <IntegrationFormSelectors
                            storeId={selectedStoreId}
                            marketplace={selectedMarketplace}
                            setSelectedMarketplace={setSelectedMarketplace}
                            setSelectedStoreId={setSelectedStoreId}
                        />
                        <SetupIntegrationForm
                            storeId={selectedStoreId}
                            marketplace={selectedMarketplace}
                        />
                    </Card>
                </Col>
                <Col style={{ flex: breakpoints.lg ? '320px 0 0' : 'auto' }}>
                    <Card style={{ width: '100%', background: '#fff' }}>
                        <Space
                            direction="vertical"
                            size="middle"
                        >
                            <Title
                                type="secondary"
                                level={3}
                            >
                                FAQ
                            </Title>
                            <Text type="secondary">
                                В этом разделе хранится список ваших магазинов и маркетплейсов подключенных к ним.
                            </Text>
                            <Button type="link">Нужна помощь?</Button>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </Content>
    );
};
export default SetupIntegrationPage;
