import { emptySplitApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCountryList: build.query<GetCountryListApiResponse, GetCountryListApiArg>({
            query: () => ({ url: '/api/country/' }),
        }),
        uploadANewFile: build.mutation<UploadANewFileApiResponse, UploadANewFileApiArg>({
            query: () => ({ url: '/api/private/file/upload/', method: 'POST' }),
        }),
        postAppApiLogin: build.mutation<PostAppApiLoginApiResponse, PostAppApiLoginApiArg>({
            query: (queryArg) => ({ url: '/api/private/login/', method: 'POST', body: queryArg.loginRequestDto }),
        }),
        getProductCategoriesList: build.query<GetProductCategoriesListApiResponse, GetProductCategoriesListApiArg>({
            query: () => ({ url: '/api/product-category/' }),
        }),
        viewPublisherLegalInfo: build.query<ViewPublisherLegalInfoApiResponse, ViewPublisherLegalInfoApiArg>({
            query: () => ({ url: '/api/publisher/v1/legal-info/' }),
        }),
        addPublisherLegalInfo: build.mutation<AddPublisherLegalInfoApiResponse, AddPublisherLegalInfoApiArg>({
            query: (queryArg) => ({
                url: '/api/publisher/v1/legal-info/',
                method: 'POST',
                body: queryArg.legalInfoDto,
            }),
        }),
        addAProductCampaignToFavorites: build.mutation<
            AddAProductCampaignToFavoritesApiResponse,
            AddAProductCampaignToFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'POST',
            }),
        }),
        removeProductCampaignFromFavorites: build.mutation<
            RemoveProductCampaignFromFavoritesApiResponse,
            RemoveProductCampaignFromFavoritesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/favorites/`,
                method: 'DELETE',
            }),
        }),
        getSourceContentTypesList: build.query<GetSourceContentTypesListApiResponse, GetSourceContentTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-content-types/' }),
        }),
        getSourcesList: build.query<GetSourcesListApiResponse, GetSourcesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source/' }),
        }),
        addASource: build.mutation<AddASourceApiResponse, AddASourceApiArg>({
            query: (queryArg) => ({ url: '/api/publisher/v1/source/', method: 'POST', body: queryArg.sourceDto }),
        }),
        viewASource: build.query<ViewASourceApiResponse, ViewASourceApiArg>({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/` }),
        }),
        putAppPrivatePublisherSourceUpdate: build.mutation<
            PutAppPrivatePublisherSourceUpdateApiResponse,
            PutAppPrivatePublisherSourceUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/`,
                method: 'PUT',
                body: queryArg.sourceDto2,
            }),
        }),
        deleteAppPrivatePublisherSourceDelete: build.mutation<
            DeleteAppPrivatePublisherSourceDeleteApiResponse,
            DeleteAppPrivatePublisherSourceDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/`, method: 'DELETE' }),
        }),
        getProductsListWithLinksBySource: build.query<
            GetProductsListWithLinksBySourceApiResponse,
            GetProductsListWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/products/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    stores: queryArg.stores,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    bids: queryArg.bids,
                    favorite: queryArg.favorite,
                },
            }),
        }),
        getProductsDetailsWithLinksBySource: build.query<
            GetProductsDetailsWithLinksBySourceApiResponse,
            GetProductsDetailsWithLinksBySourceApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/product/${queryArg.productCampaign}/`,
            }),
        }),
        getAppPrivatePublisherSourceProductlistexport: build.query<
            GetAppPrivatePublisherSourceProductlistexportApiResponse,
            GetAppPrivatePublisherSourceProductlistexportApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/products/export/` }),
        }),
        getSourceTypesList: build.query<GetSourceTypesListApiResponse, GetSourceTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-types/' }),
        }),
        getStatistics: build.query<GetStatisticsApiResponse, GetStatisticsApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistics/' }),
        }),
        postAppApiRegister: build.mutation<PostAppApiRegisterApiResponse, PostAppApiRegisterApiArg>({
            query: (queryArg) => ({
                url: '/api/private/register/',
                method: 'POST',
                body: queryArg.userRegistrationDto,
            }),
        }),
        patchAppPrivateSellerCampaignActivate: build.mutation<
            PatchAppPrivateSellerCampaignActivateApiResponse,
            PatchAppPrivateSellerCampaignActivateApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/activate/`, method: 'PATCH' }),
        }),
        patchAppPrivateSellerCampaignDisable: build.mutation<
            PatchAppPrivateSellerCampaignDisableApiResponse,
            PatchAppPrivateSellerCampaignDisableApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/disable/`, method: 'PATCH' }),
        }),
        viewTheCampaign: build.query<ViewTheCampaignApiResponse, ViewTheCampaignApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/` }),
        }),
        patchAppPrivateSellerCampaignUpdate: build.mutation<
            PatchAppPrivateSellerCampaignUpdateApiResponse,
            PatchAppPrivateSellerCampaignUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/`,
                method: 'PATCH',
                body: queryArg.campaignDto,
            }),
        }),
        getCampaignsList: build.query<GetCampaignsListApiResponse, GetCampaignsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/',
                params: { payment_types: queryArg.paymentTypes },
            }),
        }),
        getProductsListForCampaign: build.query<
            GetProductsListForCampaignApiResponse,
            GetProductsListForCampaignApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/`,
                params: { page: queryArg.page, limit: queryArg.limit },
            }),
        }),
        patchAppPrivateSellerCampaignChangecommission: build.mutation<
            PatchAppPrivateSellerCampaignChangecommissionApiResponse,
            PatchAppPrivateSellerCampaignChangecommissionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/change-commission/`,
                method: 'PATCH',
                body: queryArg.changeCommissionDto,
            }),
        }),
        patchAppPrivateSellerCampaignSetvisibility: build.mutation<
            PatchAppPrivateSellerCampaignSetvisibilityApiResponse,
            PatchAppPrivateSellerCampaignSetvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setVisibilityDto,
            }),
        }),
        getAListOfExistingMarketplaceIntegrations: build.query<
            GetAListOfExistingMarketplaceIntegrationsApiResponse,
            GetAListOfExistingMarketplaceIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/integration/' }),
        }),
        getAppPrivateSellerIntegrationOzonView: build.query<
            GetAppPrivateSellerIntegrationOzonViewApiResponse,
            GetAppPrivateSellerIntegrationOzonViewApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/` }),
        }),
        putAppPrivateSellerIntegrationOzonUpdate: build.mutation<
            PutAppPrivateSellerIntegrationOzonUpdateApiResponse,
            PutAppPrivateSellerIntegrationOzonUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'PUT',
                body: queryArg.ozonIntegrationDto,
            }),
        }),
        connectMarketplaceToOzon: build.mutation<ConnectMarketplaceToOzonApiResponse, ConnectMarketplaceToOzonApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/ozon/`,
                method: 'POST',
                body: queryArg.ozonIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        getExistMarketplaceIntegrationWithWildberries: build.query<
            GetExistMarketplaceIntegrationWithWildberriesApiResponse,
            GetExistMarketplaceIntegrationWithWildberriesApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/` }),
        }),
        putAppPrivateSellerIntegrationWildberriesUpdate: build.mutation<
            PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse,
            PutAppPrivateSellerIntegrationWildberriesUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'PUT',
                body: queryArg.wildberriesIntegrationDto,
            }),
        }),
        connectMarketplaceToWildberries: build.mutation<
            ConnectMarketplaceToWildberriesApiResponse,
            ConnectMarketplaceToWildberriesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/integration/wildberries/`,
                method: 'POST',
                body: queryArg.wildberriesIntegrationDto,
                params: { create_campaign_draft: queryArg.createCampaignDraft },
            }),
        }),
        viewSellerLegalInfo: build.query<ViewSellerLegalInfoApiResponse, ViewSellerLegalInfoApiArg>({
            query: () => ({ url: '/api/seller/v1/legal-info/' }),
        }),
        addSellerLegalInfo: build.mutation<AddSellerLegalInfoApiResponse, AddSellerLegalInfoApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/legal-info/', method: 'POST', body: queryArg.legalInfoDto }),
        }),
        getAListOfAvailableMarketplaces: build.query<
            GetAListOfAvailableMarketplacesApiResponse,
            GetAListOfAvailableMarketplacesApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace/' }),
        }),
        updateProductsInAllIntegrations: build.mutation<
            UpdateProductsInAllIntegrationsApiResponse,
            UpdateProductsInAllIntegrationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/marketplace-store/update-products/', method: 'POST' }),
        }),
        getCollectionOfMessagesForSeller: build.query<
            GetCollectionOfMessagesForSellerApiResponse,
            GetCollectionOfMessagesForSellerApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/messages/` }),
        }),
        createNewMessageInThread: build.mutation<CreateNewMessageInThreadApiResponse, CreateNewMessageInThreadApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/messages/`,
                method: 'POST',
                body: queryArg.messageDto,
            }),
        }),
        getCollectionOfReceivedNotifications: build.query<
            GetCollectionOfReceivedNotificationsApiResponse,
            GetCollectionOfReceivedNotificationsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/messenger/notification/' }),
        }),
        createNewNotification: build.mutation<CreateNewNotificationApiResponse, CreateNewNotificationApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/messenger/notification/',
                method: 'POST',
                body: queryArg.notificationDto,
            }),
        }),
        getCollectionOfThreads: build.query<GetCollectionOfThreadsApiResponse, GetCollectionOfThreadsApiArg>({
            query: () => ({ url: '/api/seller/v1/messenger/threads/' }),
        }),
        createThread: build.mutation<CreateThreadApiResponse, CreateThreadApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/messenger/threads/',
                method: 'POST',
                body: queryArg.threadDto,
            }),
        }),
        markThreadAsRead: build.mutation<MarkThreadAsReadApiResponse, MarkThreadAsReadApiArg>({
            query: (queryArg) => ({
                url: `/api/seller/v1/messenger/threads/${queryArg.threadId}/mark-read/`,
                method: 'POST',
            }),
        }),
        getSellersOnboardingForm: build.query<GetSellersOnboardingFormApiResponse, GetSellersOnboardingFormApiArg>({
            query: () => ({ url: '/api/seller/v1/onboarding/' }),
        }),
        sendSellerOnboardingForm: build.mutation<SendSellerOnboardingFormApiResponse, SendSellerOnboardingFormApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/onboarding/', method: 'POST', body: queryArg.sellersInfoDto }),
        }),
        getPaymentTypesList: build.query<GetPaymentTypesListApiResponse, GetPaymentTypesListApiArg>({
            query: () => ({ url: '/api/seller/v1/payment-types/' }),
        }),
        getProductDetails: build.query<GetProductDetailsApiResponse, GetProductDetailsApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/product/${queryArg.id}/` }),
        }),
        getProductsList: build.query<GetProductsListApiResponse, GetProductsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/product/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    stores: queryArg.stores,
                    categories: queryArg.categories,
                    marketplaces: queryArg.marketplaces,
                },
            }),
        }),
        getAllPublishers: build.query<GetAllPublishersApiResponse, GetAllPublishersApiArg>({
            query: () => ({ url: '/api/seller/v1/publishers/' }),
        }),
        getStoresList: build.query<GetStoresListApiResponse, GetStoresListApiArg>({
            query: () => ({ url: '/api/seller/v1/store/' }),
        }),
        createANewStore: build.mutation<CreateANewStoreApiResponse, CreateANewStoreApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/store/', method: 'POST', body: queryArg.storeDto }),
        }),
        viewTheStore: build.query<ViewTheStoreApiResponse, ViewTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/` }),
        }),
        putAppPrivateSellerStoreUpdate: build.mutation<
            PutAppPrivateSellerStoreUpdateApiResponse,
            PutAppPrivateSellerStoreUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/`,
                method: 'PUT',
                body: queryArg.storeDto2,
            }),
        }),
        removeTheStore: build.mutation<RemoveTheStoreApiResponse, RemoveTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/`, method: 'DELETE' }),
        }),
        getTheUserInformation: build.query<GetTheUserInformationApiResponse, GetTheUserInformationApiArg>({
            query: () => ({ url: '/api/private/user/' }),
        }),
        postAppApiUserSetRoles: build.mutation<PostAppApiUserSetRolesApiResponse, PostAppApiUserSetRolesApiArg>({
            query: (queryArg) => ({ url: '/api/private/user/roles/', method: 'POST', body: queryArg.userRolesDto }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as fullApi };
export type GetCountryListApiResponse = /** status 200 Get country list */ CountryListDto;
export type GetCountryListApiArg = void;
export type UploadANewFileApiResponse = /** status 201 Resource created */ FileDto;
export type UploadANewFileApiArg = void;
export type PostAppApiLoginApiResponse = /** status 200 Returns a user */ User;
export type PostAppApiLoginApiArg = {
    /** User Credentials */
    loginRequestDto: LoginRequestDto;
};
export type GetProductCategoriesListApiResponse = /** status 200 Get product categories list */ ProductCategoryDtoList;
export type GetProductCategoriesListApiArg = void;
export type ViewPublisherLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewPublisherLegalInfoApiArg = void;
export type AddPublisherLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddPublisherLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type AddAProductCampaignToFavoritesApiResponse = /** status 204 Empty response */ void;
export type AddAProductCampaignToFavoritesApiArg = {
    productCampaign: string;
};
export type RemoveProductCampaignFromFavoritesApiResponse = /** status 204 Resource removed */ void;
export type RemoveProductCampaignFromFavoritesApiArg = {
    productCampaign: string;
};
export type GetSourceContentTypesListApiResponse =
    /** status 200 Get source content types list */ SourceContentTypeList;
export type GetSourceContentTypesListApiArg = void;
export type GetSourcesListApiResponse = /** status 200 Get sources list */ SourceDtoList;
export type GetSourcesListApiArg = void;
export type AddASourceApiResponse = /** status 201 Resource created */ SourceDto3;
export type AddASourceApiArg = {
    sourceDto: SourceDto;
};
export type ViewASourceApiResponse = /** status 200 Resource response */ SourceDto3;
export type ViewASourceApiArg = {
    source: string;
};
export type PutAppPrivatePublisherSourceUpdateApiResponse = unknown;
export type PutAppPrivatePublisherSourceUpdateApiArg = {
    source: string;
    sourceDto2: SourceDto2;
};
export type DeleteAppPrivatePublisherSourceDeleteApiResponse = unknown;
export type DeleteAppPrivatePublisherSourceDeleteApiArg = {
    source: string;
};
export type GetProductsListWithLinksBySourceApiResponse =
    /** status 200 Get product campaign list with links by source */ {
        totalCount?: number;
        page?: number;
        limit?: number;
        items?: ProductCampaignDto[];
    };
export type GetProductsListWithLinksBySourceApiArg = {
    source: string;
    page?: number;
    limit?: number;
    stores?: {
        ''?: string[];
    };
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    bids?: {
        ''?: string[];
    };
    favorite?: boolean;
};
export type GetProductsDetailsWithLinksBySourceApiResponse =
    /** status 200 Get products details with links by source */ ProductCampaignDto;
export type GetProductsDetailsWithLinksBySourceApiArg = {
    source: string;
    productCampaign: string;
};
export type GetAppPrivatePublisherSourceProductlistexportApiResponse = unknown;
export type GetAppPrivatePublisherSourceProductlistexportApiArg = {
    source: string;
};
export type GetSourceTypesListApiResponse = /** status 200 Get source types list */ SourceTypeList;
export type GetSourceTypesListApiArg = void;
export type GetStatisticsApiResponse = unknown;
export type GetStatisticsApiArg = void;
export type PostAppApiRegisterApiResponse = unknown;
export type PostAppApiRegisterApiArg = {
    userRegistrationDto: UserRegistrationDto;
};
export type PatchAppPrivateSellerCampaignActivateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignActivateApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignDisableApiResponse = unknown;
export type PatchAppPrivateSellerCampaignDisableApiArg = {
    slug: string;
};
export type ViewTheCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type ViewTheCampaignApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignUpdateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignUpdateApiArg = {
    slug: string;
    campaignDto: CampaignDto;
};
export type GetCampaignsListApiResponse = /** status 200 Get campaigns list */ CampaignDtoList;
export type GetCampaignsListApiArg = {
    paymentTypes?: {
        ''?: number[];
    };
};
export type GetProductsListForCampaignApiResponse = /** status 200 Get products list for campaign */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductCampaign[];
};
export type GetProductsListForCampaignApiArg = {
    slug: string;
    page?: number;
    limit?: number;
};
export type PatchAppPrivateSellerCampaignChangecommissionApiResponse = unknown;
export type PatchAppPrivateSellerCampaignChangecommissionApiArg = {
    slug: string;
    changeCommissionDto: ChangeCommissionDto;
};
export type PatchAppPrivateSellerCampaignSetvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerCampaignSetvisibilityApiArg = {
    slug: string;
    setVisibilityDto: SetVisibilityDto;
};
export type GetAListOfExistingMarketplaceIntegrationsApiResponse =
    /** status 201 Resource created */ MarketplaceIntegrationListDto;
export type GetAListOfExistingMarketplaceIntegrationsApiArg = void;
export type GetAppPrivateSellerIntegrationOzonViewApiResponse = unknown;
export type GetAppPrivateSellerIntegrationOzonViewApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationOzonUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationOzonUpdateApiArg = {
    store: string;
    ozonIntegrationDto: OzonIntegrationDto;
};
export type ConnectMarketplaceToOzonApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToOzonApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    ozonIntegrationDto: OzonIntegrationDto;
};
export type GetExistMarketplaceIntegrationWithWildberriesApiResponse =
    /** status 200 Resource response */ WildberriesIntegrationDto2;
export type GetExistMarketplaceIntegrationWithWildberriesApiArg = {
    store: string;
};
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiResponse = unknown;
export type PutAppPrivateSellerIntegrationWildberriesUpdateApiArg = {
    store: string;
    wildberriesIntegrationDto: WildberriesIntegrationDto;
};
export type ConnectMarketplaceToWildberriesApiResponse = /** status 201 Resource created */ MarketplaceStore;
export type ConnectMarketplaceToWildberriesApiArg = {
    /** Create a campaign draft */
    createCampaignDraft?: boolean;
    store: string;
    wildberriesIntegrationDto: WildberriesIntegrationDto;
};
export type ViewSellerLegalInfoApiResponse = /** status 200 Resource response */ LegalInfoDto2;
export type ViewSellerLegalInfoApiArg = void;
export type AddSellerLegalInfoApiResponse = /** status 201 Resource created */ LegalInfoDto2;
export type AddSellerLegalInfoApiArg = {
    legalInfoDto: LegalInfoDto;
};
export type GetAListOfAvailableMarketplacesApiResponse = /** status 201 Resource created */ MarketplaceListDto;
export type GetAListOfAvailableMarketplacesApiArg = void;
export type UpdateProductsInAllIntegrationsApiResponse = unknown;
export type UpdateProductsInAllIntegrationsApiArg = void;
export type GetCollectionOfMessagesForSellerApiResponse = /** status 200 Get collection of messages */ MessageDtoList;
export type GetCollectionOfMessagesForSellerApiArg = {
    threadId: string;
};
export type CreateNewMessageInThreadApiResponse = /** status 201 Create new message in thread */ MessageDto;
export type CreateNewMessageInThreadApiArg = {
    threadId: string;
    messageDto: MessageDto;
};
export type GetCollectionOfReceivedNotificationsApiResponse =
    /** status 200 Get collection of received notifications */ NotificationDtoList;
export type GetCollectionOfReceivedNotificationsApiArg = void;
export type CreateNewNotificationApiResponse = /** status 201 Create new notification */ NotificationDto;
export type CreateNewNotificationApiArg = {
    notificationDto: NotificationDto;
};
export type GetCollectionOfThreadsApiResponse = /** status 200 Get collection of threads */ ThreadDtoList;
export type GetCollectionOfThreadsApiArg = void;
export type CreateThreadApiResponse = /** status 201 Create thread */ ThreadDto2;
export type CreateThreadApiArg = {
    threadDto: ThreadDto;
};
export type MarkThreadAsReadApiResponse = /** status 204 Mark thread as read */ ThreadDto2;
export type MarkThreadAsReadApiArg = {
    threadId: string;
};
export type GetSellersOnboardingFormApiResponse = /** status 200 Resource response */ SellersInfoDto2;
export type GetSellersOnboardingFormApiArg = void;
export type SendSellerOnboardingFormApiResponse = /** status 201 Resource created */ SellersInfoDto2;
export type SendSellerOnboardingFormApiArg = {
    sellersInfoDto: SellersInfoDto;
};
export type GetPaymentTypesListApiResponse = /** status 200 Get payment types list */ PaymentTypeList;
export type GetPaymentTypesListApiArg = void;
export type GetProductDetailsApiResponse = /** status 200 Get product details */ ProductDto;
export type GetProductDetailsApiArg = {
    id: string;
};
export type GetProductsListApiResponse = /** status 200 Get products list */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductDto[];
};
export type GetProductsListApiArg = {
    page?: number;
    limit?: number;
    stores?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    marketplaces?: {
        ''?: string[];
    };
};
export type GetAllPublishersApiResponse = /** status 200 Get all publishers list */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: PublisherDto[];
};
export type GetAllPublishersApiArg = void;
export type GetStoresListApiResponse = /** status 200 Get stores list */ StoreDtoList;
export type GetStoresListApiArg = void;
export type CreateANewStoreApiResponse = /** status 201 Resource created */ StoreDto3;
export type CreateANewStoreApiArg = {
    storeDto: StoreDto;
};
export type ViewTheStoreApiResponse = /** status 200 Resource response */ StoreDto3;
export type ViewTheStoreApiArg = {
    store: string;
};
export type PutAppPrivateSellerStoreUpdateApiResponse = unknown;
export type PutAppPrivateSellerStoreUpdateApiArg = {
    store: string;
    storeDto2: StoreDto2;
};
export type RemoveTheStoreApiResponse = /** status 204 Resource removed */ void;
export type RemoveTheStoreApiArg = {
    store: string;
};
export type GetTheUserInformationApiResponse = /** status 200 Returns the user information */ UserDto;
export type GetTheUserInformationApiArg = void;
export type PostAppApiUserSetRolesApiResponse = unknown;
export type PostAppApiUserSetRolesApiArg = {
    userRolesDto: UserRolesDto;
};
export type CountryDto = {
    id: string;
    title: string;
};
export type CountryListDto = {
    items?: CountryDto[];
};
export type FileDto = {
    fileName: string;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type MarketplaceStore3 = {
    settings?: {
        [key: string]: string;
    };
    id: string;
    store?: Store | null;
    marketplace?: Marketplace2 | null;
    status?: MarketplaceStoreStatusEnum3;
    successfulProductsSyncDate?: string | null;
    successfulAnalyticsSyncDate?: string | null;
    createdAt: string;
    updatedAt: string;
    secretNamespace: string;
};
export type Store = {
    id: string;
    seller?: Seller | null;
    title: string;
    slug: string;
    description: string;
    marketplaceStores: MarketplaceStore3[];
    comment?: string | null;
    logo?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    active: boolean;
    deleted: boolean;
};
export type SellerInfo = {
    id: string;
    website?: string | null;
    revenue?: RevenueRangeEnum | null;
    sellersGoal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type Country = {
    id: string;
};
export type LegalInfo = {
    id: string;
    user?: User | null;
    country?: Country | null;
    legalType?: LegalTypeEnum3;
    title: string;
    inn?: string | null;
    taxNumber?: string | null;
    mobilePhone?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Seller = {
    id: string;
    user?: User | null;
    stores: Store[];
    sellerInfo?: SellerInfo | null;
    legalInfo?: LegalInfo | null;
    erid?: string | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Publisher = {
    id: string;
    publisherVerificationCode?: string;
    user?: User | null;
    legalInfo?: LegalInfo | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
};
export type Credit = {
    id: number;
    user?: User | null;
    amount: string;
    createdAt: string;
    updatedAt: string;
};
export type User = {
    id: string;
    email: string;
    roles?: string[];
    firstname?: string | null;
    lastname?: string | null;
    seller?: Seller | null;
    publisher?: Publisher | null;
    mitgo?: string | null;
    onboarded?: boolean;
    tapfiliateCustomerId?: string | null;
    tapfiliateRef?: string | null;
    balance?: string;
    credit?: Credit | null;
    deletedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    role: string[];
    verified: boolean;
    fullName: string;
    userType: string;
    deleted: boolean;
};
export type LoginRequestDto = {
    email: string;
    password: string;
};
export type ProductCategoryDto = {
    id?: number | null;
    title: string | null;
};
export type ProductCategoryDtoList = {
    items?: ProductCategoryDto[];
};
export type LegalInfoDto2 = {
    id?: string | null;
    country: string;
    legal_type: LegalTypeEnum2 | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type LegalInfoDto = {
    country: string;
    legal_type: LegalTypeEnum | null;
    title: string | null;
    inn?: string | null;
    tax_number?: string | null;
    mobile_phone?: string | null;
};
export type SourceContentType = {
    id: number;
    title: string;
    slug: string;
};
export type SourceContentTypeList = {
    items?: SourceContentType[];
};
export type Publisher2 = object;
export type SourceDto3 = {
    id?: string | null;
    publisher?: Publisher2 | null;
    title: string | null;
    status?: SourceStatusEnum | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
    comment?: string | null;
};
export type SourceDtoList = {
    items?: SourceDto3[];
};
export type SourceDto = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type SourceDto2 = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url: string | null;
};
export type Store2 = {
    id: string;
    title: string;
    description: string;
    logo?: string | null;
};
export type Marketplace = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum3;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore2 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto2 = {
    id: string;
    marketplace_store: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    external_link: string;
    external_id: string;
    product_category: ProductCategory | null;
};
export type FeeSettingDto = {
    id: string;
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
    system_rate?: number | null;
    min_total_fee?: number | null;
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto2 | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link: string;
};
export type SourceType = {
    id: number;
    type: SourceTypeEnum;
    title: string;
    slug: string;
};
export type SourceTypeList = {
    items?: SourceType[];
};
export type UserRegistrationDto = {
    email: string;
    password: string;
    firstName?: string | null;
    lastName?: string | null;
    isSeller?: boolean;
};
export type CampaignDto2 = {
    slug?: string | null;
    marketplace_store?: MarketplaceStore2 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type FeeSetting = {
    fee_value?: string;
};
export type CampaignDto = {
    title: string | null;
    hold_time: number | null;
    fee_setting: FeeSetting | null;
};
export type CampaignDtoList = {
    items?: CampaignDto2[];
};
export type Campaign = {
    id: number;
    title: string;
    slug: string;
    description: string;
    logo?: string | null;
    marketplace_url: string;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum;
    holdTime?: number;
    fee_setting?: FeeSetting2 | null;
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
};
export type ProductCampaign = {
    id: string;
    campaign: Campaign;
    product: Product;
    fee_setting?: FeeSetting2 | null;
    visible?: boolean;
    favorite?: boolean;
};
export type ChangeCommissionDto = {
    select_all?: boolean;
    commission: number | null;
    products?: string[];
};
export type SetVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    products?: string[];
};
export type MarketplaceIntegrationDto = {
    id: string;
    store_id: string;
    marketplace_slug: MarketPlaceSlugEnum2;
};
export type MarketplaceIntegrationListDto = {
    items?: MarketplaceIntegrationDto[];
};
export type OzonIntegrationDto = {
    organization_id: string;
    seller_client_id: string;
    seller_api_key: string;
    performance_client_id: string;
    performance_api_key: string;
};
export type MarketplaceStore = {
    id: string;
};
export type WildberriesIntegrationDto2 = {
    organization_id: string;
    refresh_token: string;
    validation_key: string;
    seller_device_id: string;
    authorize_v3_token?: string | null;
    api_authorization_token: string | null;
};
export type WildberriesIntegrationDto = {
    organization_id: string;
    refresh_token: string;
    validation_key: string;
    seller_device_id: string;
    authorize_v3_token?: string | null;
    api_authorization_token: string | null;
};
export type MarketplaceDto = {
    slug: string;
    title: string;
    description?: string | null;
};
export type MarketplaceListDto = {
    items?: MarketplaceDto[];
};
export type SenderDto2 = {
    id: string;
    email?: string | null;
    firstname?: string | null;
    lastname?: string | null;
};
export type MessageDto2 = {
    id: string;
    sender: SenderDto2;
    body: string;
    isRead?: boolean;
    createdAt: string;
};
export type MessageDtoList = {
    items?: MessageDto2[];
};
export type SenderDto = {
    id: string;
};
export type MessageDto = {
    sender: SenderDto;
    body: string;
};
export type SellerDto = {
    id?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type PublisherDto = {
    id?: string | null;
    publisherVerificationCode: string;
    firstName: string;
    lastName: string;
    avatar?: string | null;
    legalInfo?: LegalInfoDto2 | null;
};
export type UserDto = {
    id?: string | null;
    seller?: SellerDto | null;
    publisher?: PublisherDto | null;
    country?: string | null;
    timezone?: string | null;
    avatar?: string | null;
    roles?: string[];
    onboarded?: boolean;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
};
export type NotificationDto2 = {
    id: string;
    createdBy: UserDto | null;
    subject: string;
    body: string;
    createdAt: string;
    read: boolean;
};
export type NotificationDtoList = {
    items?: NotificationDto2[];
};
export type UserDto2 = object;
export type NotificationDto = {
    createdBy: UserDto2 | null;
    subject: string;
    body: string;
    read: boolean;
};
export type ThreadMemberDto3 = {
    id: string;
    read?: boolean;
};
export type ThreadDto3 = {
    id?: string | null;
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto3;
    member: ThreadMemberDto3;
    body: string;
    createdAt: string;
};
export type ThreadDtoList = {
    items?: ThreadDto3[];
};
export type ThreadMemberDto2 = {
    id: string;
    read?: boolean;
};
export type ThreadDto2 = {
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto2;
    member: ThreadMemberDto2;
    body: string;
};
export type ThreadMemberDto = {
    id: string;
    read?: boolean;
};
export type ThreadDto = {
    id?: string | null;
    createdBy: string;
    subject: string;
    holder: ThreadMemberDto;
    member: ThreadMemberDto;
    body: string;
    createdAt: string;
};
export type SellersInfoDto2 = {
    website?: string | null;
    revenue_range?: RevenueRangeEnum2 | null;
    sellers_goal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type SellersInfoDto = {
    website?: string | null;
    revenue_range?: RevenueRangeEnum | null;
    sellers_goal?: (
        | 'increase_sales'
        | 'obtaining_statistics'
        | 'order_tracking'
        | 'analytics'
        | 'communication_with_webmasters'
        | 'increasing_reviews'
        | 'other'
    )[];
};
export type PaymentTypeList = {
    items?: PaymentType[];
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    external_link: string;
    external_id: string;
};
export type MarketplaceStoreDto2 = {
    id: string;
    marketplace_title: string;
    marketplace_slug: MarketPlaceSlugEnum3;
    storeTitle?: string | null;
    status: MarketplaceStoreStatusEnum2;
};
export type StoreDto3 = {
    id: string;
    title: string;
    description: string;
    comment?: string | null;
    logo?: string | null;
    marketplaces?: MarketplaceStoreDto2[];
    active: boolean;
};
export type StoreDtoList = {
    items?: StoreDto3[];
};
export type StoreDto = {
    title: string;
    description: string;
    comment?: string | null;
    logo?: string | null;
};
export type MarketplaceStoreDto = {
    id: string;
    marketplace_title: string;
    marketplace_slug: MarketPlaceSlugEnum;
    storeTitle?: string | null;
    status: MarketplaceStoreStatusEnum;
};
export type StoreDto2 = {
    title: string;
    description: string;
    comment?: string | null;
    logo?: string | null;
    marketplaces?: MarketplaceStoreDto[];
};
export type UserRolesDto = {
    is_publisher?: boolean;
    is_seller?: boolean;
    publisher?: boolean | null;
    seller?: boolean | null;
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum MarketplaceStoreStatusEnum3 {
    Connected = 'connected',
    Disconnected = 'disconnected',
}
export enum RevenueRangeEnum {
    FirstLevel = 'first_level',
    SecondLevel = 'second_level',
    ThirdLevel = 'third_level',
    FourthLevel = 'fourth_level',
}
export enum LegalTypeEnum3 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum LegalTypeEnum2 {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum LegalTypeEnum {
    NaturalPerson = 'natural_person',
    IndividualEntrepreneur = 'individual_entrepreneur',
    Company = 'company',
}
export enum SourceStatusEnum {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export enum MarketPlaceSlugEnum3 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum SourceTypeEnum {
    SocialNetwork = 'social_network',
    Messenger = 'messenger',
    Other = 'other',
}
export enum MarketPlaceSlugEnum2 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum RevenueRangeEnum2 {
    FirstLevel = 'first_level',
    SecondLevel = 'second_level',
    ThirdLevel = 'third_level',
    FourthLevel = 'fourth_level',
}
export enum MarketplaceStoreStatusEnum2 {
    Connected = 'connected',
    Disconnected = 'disconnected',
}
export enum MarketPlaceSlugEnum {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
}
export enum MarketplaceStoreStatusEnum {
    Connected = 'connected',
    Disconnected = 'disconnected',
}
export const {
    useGetCountryListQuery,
    useUploadANewFileMutation,
    usePostAppApiLoginMutation,
    useGetProductCategoriesListQuery,
    useViewPublisherLegalInfoQuery,
    useAddPublisherLegalInfoMutation,
    useAddAProductCampaignToFavoritesMutation,
    useRemoveProductCampaignFromFavoritesMutation,
    useGetSourceContentTypesListQuery,
    useGetSourcesListQuery,
    useAddASourceMutation,
    useViewASourceQuery,
    usePutAppPrivatePublisherSourceUpdateMutation,
    useDeleteAppPrivatePublisherSourceDeleteMutation,
    useGetProductsListWithLinksBySourceQuery,
    useGetProductsDetailsWithLinksBySourceQuery,
    useGetAppPrivatePublisherSourceProductlistexportQuery,
    useGetSourceTypesListQuery,
    useGetStatisticsQuery,
    usePostAppApiRegisterMutation,
    usePatchAppPrivateSellerCampaignActivateMutation,
    usePatchAppPrivateSellerCampaignDisableMutation,
    useViewTheCampaignQuery,
    usePatchAppPrivateSellerCampaignUpdateMutation,
    useGetCampaignsListQuery,
    useGetProductsListForCampaignQuery,
    usePatchAppPrivateSellerCampaignChangecommissionMutation,
    usePatchAppPrivateSellerCampaignSetvisibilityMutation,
    useGetAListOfExistingMarketplaceIntegrationsQuery,
    useGetAppPrivateSellerIntegrationOzonViewQuery,
    usePutAppPrivateSellerIntegrationOzonUpdateMutation,
    useConnectMarketplaceToOzonMutation,
    useGetExistMarketplaceIntegrationWithWildberriesQuery,
    usePutAppPrivateSellerIntegrationWildberriesUpdateMutation,
    useConnectMarketplaceToWildberriesMutation,
    useViewSellerLegalInfoQuery,
    useAddSellerLegalInfoMutation,
    useGetAListOfAvailableMarketplacesQuery,
    useUpdateProductsInAllIntegrationsMutation,
    useGetCollectionOfMessagesForSellerQuery,
    useCreateNewMessageInThreadMutation,
    useGetCollectionOfReceivedNotificationsQuery,
    useCreateNewNotificationMutation,
    useGetCollectionOfThreadsQuery,
    useCreateThreadMutation,
    useMarkThreadAsReadMutation,
    useGetSellersOnboardingFormQuery,
    useSendSellerOnboardingFormMutation,
    useGetPaymentTypesListQuery,
    useGetProductDetailsQuery,
    useGetProductsListQuery,
    useGetAllPublishersQuery,
    useGetStoresListQuery,
    useCreateANewStoreMutation,
    useViewTheStoreQuery,
    usePutAppPrivateSellerStoreUpdateMutation,
    useRemoveTheStoreMutation,
    useGetTheUserInformationQuery,
    usePostAppApiUserSetRolesMutation,
} = injectedRtkApi;
